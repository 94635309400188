<template>
  <div>
    <v-container>
      <v-row>
        <v-col style="color:white" class="d-flex" cols="12"
          ><v-icon  color="white" class="pr-1">mdi-dice-multiple-outline</v-icon
          >อัตราการแพ้ชนะ</v-col
        >
      </v-row>
      <div class="divider div-transparent my-4"></div>
      <v-row class="justify-space-between px-1">
        <v-col
          cols="6"
          xl="3"
          md="3"
          lg="3"
          sm="3"
          class="pa-0 pa-xl-1 pa-lg-1 pa-md-1"
        >
          <v-row style="color: white" class="text-center bg-all ma-1">
            <v-col cols="12" class="pb-0">เล่นทั้งหมด</v-col>
            <v-col cols="12" class="pt-0">{{
              rpsHistory.match == null ? "ไม่มีข้อมูล" : rpsHistory.match
            }}</v-col>
          </v-row>
        </v-col>
        <v-col
          cols="6"
          xl="3"
          md="3"
          lg="3"
          sm="3"
          class="pa-0 pa-xl-1 pa-lg-1 pa-md-1"
        >
          <v-row style="color: white" class="text-center bg-win ma-1">
            <v-col cols="12" class="pb-0">ชนะ</v-col>
            <v-col cols="12" class="pt-0">{{
              rpsHistory.win == null ? "ไม่มีข้อมูล" : rpsHistory.win
            }}</v-col>
          </v-row></v-col
        >
        <v-col
          cols="6"
          xl="3"
          md="3"
          lg="3"
          sm="3"
          class="pa-0 pa-xl-1 pa-lg-1 pa-md-1"
        >
          <v-row style="color: white" class="text-center bg-lose ma-1">
            <v-col cols="12" class="pb-0">แพ้</v-col>
            <v-col cols="12" class="pt-0">{{
              rpsHistory.lose == null ? "ไม่มีข้อมูล" : rpsHistory.lose
            }}</v-col>
          </v-row></v-col
        >

        <v-col
          cols="6"
          xl="3"
          md="3"
          lg="3"
          sm="3"
          class="pa-0 pa-xl-1 pa-lg-1 pa-md-1"
        >
          <v-row style="color: white" class="text-center bg-winrate ma-1">
            <v-col cols="12" class="pb-0">อัตราชนะ</v-col>
            <v-col cols="12" class="pt-0">{{
              rpsHistory.winrate == null ? "ไม่มีข้อมูล" : rpsHistory.winrate
            }}</v-col>
          </v-row></v-col
        >
      </v-row>
      <v-row>
        <v-col style="color:white" cols="12" class="d-flex"
          ><v-icon color="white" class="pr-1">mdi-clock-outline</v-icon>ผลการเล่น 20
          รายการล่าสุด</v-col
        >
      </v-row>
      <div class="divider div-transparent my-4"></div>
      <v-row v-if="rpsHistory.length != 0" class="justify-space-between">
        <v-col cols="12">
          <v-row>
            <v-col> <div class="pl-5">เจ้าบ้าน</div></v-col>
            <v-col class="d-flex flex-column align-end"
              ><div class="pr-5 text-end">คู่แข่ง</div></v-col
            >
          </v-row>
        </v-col>
        <v-col
          v-for="(item, index) in rpsHistory.datas"
          :key="index"
          cols="12"
          lg="6"
          class="mb-0 text-custom"
        >
          <v-row class="ma-1 bg-main">
            <v-col cols="12" c>
              <v-row
                :class="
                  item.winner == 0
                    ? 'bg-draw-item justify-space-between pa-2'
                    : (item.winner == 1 && item.p1 == user.username) ||
                      (item.winner == 2 && item.p2 == user.username)
                    ? 'bg-win-item justify-space-between pa-2'
                    : 'bg-lose-item justify-space-between pa-2'
                "
                ><div class="d-flex align-center">
                  <v-icon class="pr-1">mdi-account-circle</v-icon>

                  {{ item.p1 }}-{{
                    item.p1 == user.username ? "คุณ" : "คู่แข่ง"
                  }}
                </div>
                <div class="d-flex align-center">
                  {{ resultRPS(item, user) }}
                </div>
                <div class="d-flex align-center">
                  <v-icon class="pr-1">mdi-account-circle</v-icon>
                  <div>
                    {{ item.p2 }}-{{
                      item.p2 == user.username ? "คุณ" : "คู่แข่ง"
                    }}
                  </div>
                </div></v-row
              >
            </v-col>
            <v-col cols="12">
              <v-row style="border-radius: 8px" class="justify-center">
                <v-col cols="12" class="" style="border-radius: 8px">
                  <v-row class="justify-center">
                    <v-col cols="5" xl="1" lg="2" md="2" sm="2" class="">
                      <v-row class="justify-center">
                        <v-col
                          cols="6"
                          class="
                            d-flex
                            flex-column
                            justify-center
                            align-end
                            pr-0
                          "
                          ><div style="color: black">
                            {{ nameRPS(item.p1bet) }}
                          </div>
                        </v-col>
                        <v-col
                          cols="6"
                          class="pl-1 d-flex align-center justify-center pr-0"
                        >
                          <v-img width="50%" :src="imageRPS(item.p1bet)"></v-img
                        ></v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="2" class="d-flex align-center justify-center"
                      ><div style="color: black">VS</div></v-col
                    >
                    <v-col cols="5" xl="1" lg="2" md="2" sm="2" class="">
                      <v-row class="justify-center">
                        <v-col
                          cols="6"
                          class="pl-1 d-flex align-center justify-center pr-0"
                        >
                          <v-img width="50%" :src="imageRPS(item.p2bet)"></v-img
                        ></v-col>
                        <v-col
                          cols="6"
                          class="
                            d-flex
                            flex-column
                            justify-center
                            align-start
                            pl-1
                          "
                          ><div style="color: black">
                            {{ nameRPS(item.p2bet) }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row style="color: black" class="justify-space-between pa-2">
                <div class="d-flex align-center">
                  <v-icon color="black" class="pr-1">mdi-cash-multiple</v-icon>
                  ฿{{ item.amount }}
                </div>
                <div class="d-flex align-center">
                  <v-icon color="black" class="pr-1">mdi-clock-outline</v-icon>

                  {{
                    moment(item.createdAt)
                      .lang("th")
                      .format("Do MMMM YYYY, HH:mm")
                  }}
                </div>
                <div class="d-flex align-center">#{{ item.roomId }}</div>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-else style="color:white" class="justify-center pa-1">ไม่มีข้อมูล</v-row>
    </v-container>
  </div>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      rpsHistory: (state) => state.game.rpsHistory,
      user: (state) => state.authen.user,
    }),
  },
  methods: {
    moment,
    imageRPS(rps) {
      switch (rps) {
        case "r":
          return "/hand-rock.png";

        case "p":
          return "/hand-paper.png";

        case "s":
          return "/hand-scissors.png";
      }
    },
    nameRPS(rps) {
      switch (rps) {
        case "r":
          return "ค้อน";

        case "p":
          return "กระดาษ";

        case "s":
          return "กรรไกร";
      }
    },
    resultRPS(item, user) {
      if (item.winner == 0) return "เสมอ";
      if (item.winner == 1 && item.p1 == user.username) return "ชนะ";
      if (item.winner == 2 && item.p2 == user.username) return "ชนะ";
      return "แพ้";
    },
  },
};
</script>
<style scoped>
.div-transparent:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 2px;
  background-image: linear-gradient(
    to right,
    transparent,
    rgb(231, 90, 43),
    transparent
  );
}
.divider {
  position: relative;
  height: 1px;
}
.bg-all {
  border-radius: 8px;
  background-color: #33539e;
  color: #000;
}
.bg-win {
  border-radius: 8px;
  background-color: #bfb8da;
  color: #000;
}
.bg-lose {
  border-radius: 8px;
  background-color: #e8b7d4;
  color: #000;
}
.bg-winrate {
  border-radius: 8px;
  background-color: #a5678e;
  color: #000;
}
.bg-main {
  border-radius: 8px;
  background: linear-gradient(
    130deg,
    rgba(215, 216, 220, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
.bg-lose-item {
  border-radius: 8px;
  background-color: #de5b6d;
}
.bg-win-item {
  border-radius: 8px;
  background-color: #478ba2;
}
.bg-draw-item {
  border-radius: 8px;
  background-color: #e9765b;
}

@media screen and (max-width: 458px) {
  .text-custom {
    font-size: 13px !important;
  }
}
</style>
