<template>
  <div class="px-4" style="padding-bottom: 140px; padding-top: 30px">
    <v-container class="pt-11 pb-16 px-lx-16 px-lg-16 section-content-custom">
      <v-row style="margin-top: -75px" class="pb-1 pb-lg-1 pb-xl-1">
        <v-col
          cols="9"
          lg="4"
          md="4"
          sm="6"
          xl="4"
          class="title-custom text-center"
        >
          {{ userProfile.length != 0 ? "เล่นเกม" : "ทดลองเล่นเกม" }}</v-col
        >
      </v-row>
      <div class="pt-12"><SectionGamePlay /></div>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
import SectionGamePlay from "../../components/s_content_game";
export default {
  data: () => ({}),
  components: {
    SectionGamePlay,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.user.userProfile,
    }),
  },
};
</script>
<style scoped>
</style>