<template>
  <div class="px-4" style="padding-bottom: 140px; padding-top: 30px">
    <v-container class="pb-16 px-lx-16 px-lg-16 section-content-custom">
      <v-row style="margin-top: -43px">
        <v-col
          cols="9"
          lg="4"
          md="4"
          sm="6"
          xl="4"
          class="title-custom text-center"
          >รางวัลประจำเดือน</v-col
        >
      </v-row>
      <v-row class="pt-12 justify-center">
        <v-col cols="10" sm="10" md="5" xl="5" lg="5" class="mb-4">
          <v-row>
            <v-col cols="8">
              <v-select
                dark
                @change="getAllRanking()"
                hide-details
                flat
                v-model="month"
                :items="months"
                :item-text="'month'"
                :item-value="'monthId'"
                label="Solo field"
                solo
              ></v-select
            ></v-col>
            <v-col class="pl-0" cols="4">
              <v-select
                dark
                hide-details
                flat
                v-model="year"
                :items="years"
                :item-text="'year'"
                :item-value="'value'"
                solo
              ></v-select
            ></v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="isOpenRankingCashBack"
          cols="11"
          lg="10"
          xl="10"
          md="9"
          sm="8"
          class="border-card mb-4 text-center"
        >
          <v-row class="justify-center">
            <v-col cols="11" sm="8" md="8" lg="8" xl="8">
              <div class="form-text-header">
                10 อันดับ ผู้ที่ได้รับเงินคืนสูงที่สุด
              </div></v-col
            >
          </v-row>
          <div class="black mt-4">
            <v-data-table
              dark
              :loading="isLoading"
              :headers="headersCashBack"
              :items="cashback"
              class="elevation-1"
              mobile-breakpoint="0"
            >
              <template #item="{ item }">
                <tr
                  :class="
                    cashback.indexOf(item) == 0
                      ? 'no1'
                      : cashback.indexOf(item) == 1
                      ? 'no2'
                      : cashback.indexOf(item) == 2
                      ? 'no3'
                      : ''
                  "
                >
                  <td>
                    {{ cashback.indexOf(item) + 1 }}
                  </td>
                  <td>
                    {{ item.username ? item.username : "-" }}
                  </td>
                  <td>
                    {{ item.amount ? item.amount.toLocaleString() : "-" }}
                  </td>
                  <td>
                    {{ item.reward }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div></v-col
        >
        <v-col
          v-if="isOpenRankingCashBack2"
          cols="11"
          lg="10"
          xl="10"
          md="9"
          sm="8"
          class="border-card mb-4 text-center"
        >
          <v-row class="justify-center">
            <v-col cols="11" sm="8" md="8" lg="8" xl="8">
              <div class="form-text-header">
                10 อันดับ ผู้ที่มียอดเสียสูงที่สุด
              </div></v-col
            >
          </v-row>

          <div class="black mt-4">
            <v-data-table
              dark
              :loading="isLoading"
              :headers="headersCashBack2"
              :items="cashback2"
              class="elevation-1"
              mobile-breakpoint="0"
            >
              <template #item="{ item }">
                <tr
                  :class="
                    cashback2.indexOf(item) == 0
                      ? 'no1'
                      : cashback2.indexOf(item) == 1
                      ? 'no2'
                      : cashback2.indexOf(item) == 2
                      ? 'no3'
                      : ''
                  "
                >
                  <td>
                    {{ cashback2.indexOf(item) + 1 }}
                  </td>
                  <td>
                    {{ item.username ? item.username : "-" }}
                  </td>
                  <td>
                    {{ item.amount ? item.amount.toLocaleString() : "-" }}
                  </td>
                  <td>
                    {{ item.reward }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div></v-col
        >
        <v-col
          v-if="isOpenRankinginvite"
          cols="11"
          lg="10"
          xl="10"
          md="9"
          sm="8"
          class="border-card mb-4 text-center"
        >
          <v-row class="justify-center">
            <v-col cols="11" sm="8" md="8" lg="8" xl="8">
              <div class="form-text-header">
                10 อันดับ ผู้ที่ได้โบนัสชวนเพื่อนสูงที่สุด
              </div></v-col
            >
          </v-row>

          <div class="black mt-4">
            <v-data-table
              dark
              :loading="isLoading"
              :headers="headersInvite"
              :items="invite"
              class="elevation-1"
              mobile-breakpoint="0"
            >
              <template #item="{ item }">
                <tr
                  :class="
                    invite.indexOf(item) == 0
                      ? 'no1'
                      : invite.indexOf(item) == 1
                      ? 'no2'
                      : invite.indexOf(item) == 2
                      ? 'no3'
                      : ''
                  "
                >
                  <td>
                    {{ invite.indexOf(item) + 1 }}
                  </td>
                  <td>
                    {{ item.username ? item.username : "-" }}
                  </td>
                  <td>
                    {{ item.amount ? item.amount.toLocaleString() : "-" }}
                  </td>
                  <td>
                    {{ item.reward }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div></v-col
        >
      </v-row>
    </v-container>
  </div>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";
export default {
  data: () => ({
    month: moment().format("M"),
    year: 2022,
    years: [
      {
        year: 2021,
        value: 2021,
      },
      {
        year: 2022,
        value: 2022,
      },
      {
        year: 2023,
        value: 2023,
      },
      {
        year: 2024,
        value: 2024,
      },
      {
        year: 2025,
        value: 2025,
      },
    ],
    isLoading: false,
    headersCashBack: [
      {
        text: "ลำดับ",
        align: "center",
        value: "name",
        sortable: false,
        value: "number",
      },
      {
        text: "ชื่อผู้ใช้	",
        align: "center",
        value: "username",
        sortable: false,
      },
      { text: "ยอดเงินคืน	", align: "center", value: "amount", sortable: false },
      {
        text: "รางวัลที่ได้รับ",
        align: "center",
        value: "reward",
        sortable: false,
      },
    ],
    headersCashBack2: [
      {
        text: "ลำดับ",
        align: "center",
        value: "name",
        sortable: false,
        value: "number",
      },
      {
        text: "ชื่อผู้ใช้	",
        align: "center",
        value: "username",
        sortable: false,
      },
      { text: "ยอดเสียรวม	", align: "center", value: "amount", sortable: false },
      {
        text: "รางวัลที่ได้รับ",
        align: "center",
        value: "reward",
        sortable: false,
      },
    ],
    headersInvite: [
      {
        text: "ลำดับ",
        align: "center",
        value: "name",
        sortable: false,
        value: "number",
      },
      {
        text: "ชื่อผู้ใช้	",
        align: "center",
        value: "username",
        sortable: false,
      },
      { text: "โบนัสรวม", align: "center", value: "amount", sortable: false },
      {
        text: "รางวัลที่ได้รับ",
        align: "center",
        value: "reward",
        sortable: false,
      },
    ],

    months: [
      { month: "มกราคม", monthId: "1" },
      { month: "กุมภาพันธ์", monthId: "2" },
      { month: "มีนาคม", monthId: "3" },
      { month: "เมษายน", monthId: "4" },
      { month: "พฤษภาคม", monthId: "5" },
      { month: "มิถุนายน", monthId: "6" },
      { month: "กรกฎาคม", monthId: "7" },
      { month: "สิงหาคม", monthId: "8" },
      { month: "กันยายน", monthId: "9" },
      { month: "ตุลาคม", monthId: "10" },
      { month: "พฤศจิกายน", monthId: "11" },
      { month: "ธันวาคม", monthId: "12" },
    ],
  }),
  computed: {
    ...mapState({
      cashback: (state) => state.ranking.cashback,
      cashback2: (state) => state.ranking.cashback2,
      invite: (state) => state.ranking.invite,
      isOpenRankingCashBack: (state) => state.ranking.isOpenRankingCashBack,
      isOpenRankingCashBack2: (state) => state.ranking.isOpenRankingCashBack2,
      isOpenRankinginvite: (state) => state.ranking.isOpenRankinginvite,
    }),
  },
  methods: {
    async getAllRanking() {
      let body = {
        month: this.month,
        year: this.year,
      };
      this.isLoading = true;

      await this.$store.dispatch("ranking/getRankingCashBack", body);
      await this.$store.dispatch("ranking/getRankingCashBack2", body);
      await this.$store.dispatch("ranking/getRankinginvite", body);
      this.isLoading = false;
    },
  },
  async mounted() {
    await this.getAllRanking();
  },
};
</script>
<style  scoped>
@media only screen and (max-width: 768px) {
  .form-text-header-desktop {
    font-size: 15px !important;
  }
}
.divider-custom {
  background: linear-gradient(
    90deg,
    rgba(255, 235, 0, 1) 0%,
    rgba(121, 9, 9, 1) 50%,
    rgba(255, 235, 0, 1) 100%
  );
}
.border-card {
  border-radius: 8px !important;
  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(150, 16, 151, 0.8);
  background: url("../../assets/BG-SC1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 10px #ee331d !important ;
}
.text-title {
  font-size: 20px;
  background: #cf0000;
  background: -webkit-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: -moz-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.form-text-header {
  font-size: 17px;
  color: #fff;
  font-weight: bold;
  background: url("../../assets/Box-Topic.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 25px;
}

.title-custom {
  top: -1.4% !important;
}
/* .no1 {
  color: #482e15 !important;
  font-weight: bold !important;
  background: linear-gradient(
    90deg,
    rgba(163, 121, 45, 1) 0%,
    rgba(255, 251, 199, 1) 100%
  ) !important;
}
.no2 {
  color: #482e15 !important;
  font-weight: bold !important;
  background: linear-gradient(
    90deg,
    rgba(136, 128, 125, 1) 0%,
    rgba(255, 255, 250, 1) 100%
  ) !important;
}
.no3 {
  color: #482e15 !important;
  font-weight: bold !important;
  background: linear-gradient(
    90deg,
    rgba(143, 72, 26, 1) 0%,
    rgba(216, 191, 160, 1) 100%
  ) !important;
} */
</style>