import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueAxios from "vue-axios";
import axios from "axios";
import interceptorsSetup from "./plugins/interceptors";
import "vue-toast-notification/dist/theme-sugar.css";
import VueToast from "vue-toast-notification";
import VuePageTransition from "vue-page-transition";
import VueGtag from "vue-gtag";

Vue.use(VuePageTransition);
Vue.use(VueToast);
Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
interceptorsSetup();
Vue.use(VueGtag, {
  config: { id: "G-J8W2VRDBFK" },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
