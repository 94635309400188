import config from "../../config/config";
import { globalAxios } from "../../../axios-config";
const state = () => ({
  cashback: [],
  cashback2: [],
  invite: [],
  isOpenRankingCashBack: true,
  isOpenRankingCashBack2: true,
  isOpenRankinginvite: true,
});

const mutations = {
  setDataCashBack(state, payload) {
    state.isOpenRankingCashBack = payload.enabled;
    state.cashback = payload.datas;
  },
  setDataCashBack2(state, payload) {
    state.isOpenRankingCashBack2 = payload.enabled;
    state.cashback2 = payload.datas;
  },
  setDataInvite(state, payload) {
    state.isOpenRankinginvite = payload.enabled;
    state.invite = payload.datas;
  },
};

const actions = {
  async getRankingCashBack({ commit }, body) {
    try {
      let response = await globalAxios({
        method: "get",
        url: `/${config.api.ranking}/cashback/${body.month}/${body.year}`,
      });
      commit("setDataCashBack", response.data);
      return response.data;
    } catch (error) {
      return error.response.data.errors;
    }
  },
  async getRankingCashBack2({ commit }, body) {
    try {
      let response = await globalAxios({
        method: "get",
        url: `/${config.api.ranking}/cashback2/${body.month}/${body.year}`,
      });

      commit("setDataCashBack2", response.data);
      return response.data;
    } catch (error) {
      return error.response.data.errors;
    }
  },
  async getRankinginvite({ commit }, body) {
    try {
      let response = await globalAxios({
        method: "get",
        url: `/${config.api.ranking}/invite/${body.month}/${body.year}`,
      });
      commit("setDataInvite", response.data);
      return response.data;
    } catch (error) {
      return error.response.data.errors;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
