<template>
  <div>
    <div
      class="card-loading-screen d-flex align-center justify-center flex-column"
    >
      <v-img
        class="square hithere"
        src="../assets/logo.png"
        lazy-src="../assets/logo.png"
        contain
      ></v-img>
      <!-- <div v-if="loadingPercent < 100">
        <div class="progress-bar">
          <div class="fill" :style="{ width: loaded }">{{ loaded }}</div>
        </div>
      </div>
      <div v-else>Page Loaded</div> -->
      <div class="text-loading">กำลังโหลดหน้าเว็บ</div>

      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["loaded", "loadingPercent"],
};
</script>

<style scoped>
.btn_block {
  text-decoration: none;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(247, 173, 45, 1) 0%,
    rgb(218, 128, 10) 100%
  );
  color: black;
  width: 150px;
}
.btn_block {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(247, 173, 45, 1) 0%,
    rgb(218, 128, 10) 100%
  );
  color: black;
}
.card-loading-screen {
  width: 100%;
  /* background-image: url("/BG3.png"); */
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  height: 100vh;
  padding: 100px 10px;
  background: linear-gradient(
    180deg,
    rgba(193, 40, 174, 1) 0%,
    rgba(52, 1, 40, 1) 100%
  );
}
.square {
  display: inline-block;
}
.hithere {
  animation: hithere 1.3s ease infinite;
}
@keyframes hithere {
  30% {
    transform: scale(1.2);
  }
  40%,
  60% {
    transform: rotate(-20deg) scale(1.2);
  }
  50% {
    transform: rotate(20deg) scale(1.2);
  }
  70% {
    transform: rotate(0deg) scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fcf;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.text-loading {
  font-size: 25px;
  color: white;
  padding-bottom: 20px;
  text-shadow: 2px 2px #000000;
}
</style>
