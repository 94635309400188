/* eslint-disable prettier/prettier */
import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import ranking from "./modules/ranking";
import transection from "./modules/transection";
import invite from "./modules/invite";
import game from "./modules/game";
import promotion from "./modules/promotion";
import cashback from "./modules/cashback";
import setting from "./modules/setting";
import news from "./modules/news";


Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    ranking,
    transection,
    invite,
    game,
    promotion,
    cashback,
    setting,
    news
  },
});
