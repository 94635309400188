<template>
  <v-row style="background-color: #3e133a">
    <v-col>
      <v-container>
        <div
          class="text-start text-promotion-header"
          style="text-shadow: 0px 1px 3px #1a0216"
        >
          ประวัติการเงิน
        </div>
        <div class="text-center pt-3">
          <v-col
            cols="12"
            class="border-card mb-4"
            style="text-shadow: 0px 1px 3px #1a0216"
          >
            ประวัติการเติมเงิน
            <div class="black mt-4">
              <v-data-table
                dark
                :loading="isLoading"
                :headers="headersDeposit"
                :items="historydeposit.slice(0, 10)"
                class="elevation-1"
                mobile-breakpoint="0"
                hide-default-footer
              >
                <template v-slot:[`item.number`]="{ item }">
                  <div>
                    {{ historydeposit.indexOf(item) + 1 }}
                  </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <div
                    :class="
                      item.status == 'success'
                        ? 'text-success'
                        : item.status == 'faild'
                        ? 'text-faild-or-cancel'
                        : item.status == 'waiting'
                        ? 'text-waitting'
                        : item.status == 'progress'
                        ? 'text-waitting'
                        : item.status == 'transfer'
                        ? 'text-waitting'
                        : ''
                    "
                  >
                    {{
                      item.status == "success"
                        ? "สำเร็จ"
                        : item.status == "faild"
                        ? "กรุณาติดต่อทีมงาน"
                        : item.status == "waiting"
                        ? "รอดำเนินการ"
                        : item.status == "progress"
                        ? "กำลังดำเนินการ"
                        : item.status == "transfer"
                        ? "กำลังฝากเงิน"
                        : ""
                    }}
                  </div>
                </template>
                <template v-slot:[`item.description`]="{ item }">
                  <div>
                    {{ item.description == null ? "-" : item.description }}
                  </div>
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                  <div>
                    {{
                      item.createdAt == null
                        ? "-"
                        : moment(item.createdAt).format("DD/MM/YYYY HH:mm")
                    }}
                  </div>
                </template>
              </v-data-table>
            </div></v-col
          >

          <v-col
            cols="12"
            class="border-card"
            style="text-shadow: 0px 1px 3px #1a0216"
          >
            ประวัติการถอนเงิน
            <div class="black mt-4">
              <v-data-table
                dark
                :loading="isLoading"
                :headers="headersWithdraw"
                class="elevation-1"
                :items="historywithdraw.slice(0, 10)"
                mobile-breakpoint="0"
                hide-default-footer
              >
                <template v-slot:[`item.number`]="{ item }">
                  <div>
                    {{ historywithdraw.indexOf(item) + 1 }}
                  </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <div
                    :class="
                      item.status == 'success'
                        ? 'text-success'
                        : item.status == 'faild'
                        ? 'text-faild-or-cancel'
                        : item.status == 'cancel'
                        ? 'text-faild-or-cancel'
                        : item.status == 'waiting'
                        ? 'text-waitting'
                        : item.status == 'progress'
                        ? 'text-waitting'
                        : item.status == 'transfer'
                        ? 'text-waitting'
                        : ''
                    "
                  >
                    {{
                      item.status == "success"
                        ? "สำเร็จ"
                        : item.status == "faild"
                        ? "กรุณาติดต่อทีมงาน"
                        : item.status == "cancel"
                        ? "ยกเลิก"
                        : item.status == "waiting"
                        ? "รอดำเนินการ"
                        : item.status == "progress"
                        ? "กำลังดำเนินการ"
                        : item.status == "transfer"
                        ? "กำลังโอนเงิน"
                        : ""
                    }}
                  </div>
                </template>
                <template v-slot:[`item.description`]="{ item }">
                  <div>
                    {{ item.description == null ? "-" : item.description }}
                  </div>
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                  <div>
                    {{
                      item.createdAt == null
                        ? "-"
                        : moment(item.createdAt).format("DD/MM/YYYY HH:mm")
                    }}
                  </div>
                </template>
              </v-data-table>
            </div></v-col
          >
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";
export default {
  data: () => ({
    isLoading: false,
    headersWithdraw: [
      {
        text: "ลำดับ",
        align: "center",
        value: "name",
        sortable: false,
        value: "number",
      },

      { text: "จำนวนเงิน", align: "center", value: "amount", sortable: false },
      {
        text: "สถานะ",
        align: "center",
        value: "status",
        sortable: false,
      },
      {
        text: "รายละเอียด",
        align: "center",
        value: "description",
        sortable: false,
      },
      {
        text: "เวลา",
        align: "center",
        value: "createdAt",
        sortable: false,
      },
    ],
    headersDeposit: [
      {
        text: "ลำดับ",
        align: "center",
        value: "name",
        sortable: false,
        value: "number",
      },

      { text: "จำนวนเงิน	", align: "center", value: "amount", sortable: false },
      {
        text: "สถานะ",
        align: "center",
        value: "status",
        sortable: false,
      },
      {
        text: "รายละเอียด",
        align: "center",
        value: "description",
        sortable: false,
      },
      {
        text: "เวลา",
        align: "center",
        value: "createdAt",
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapState({
      historywithdraw: (state) => state.transection.historywithdraw,
      historydeposit: (state) => state.transection.historydeposit,
    }),
  },
  methods: {
    moment,
  },
};
</script>

<style  scoped>
.text-promotion-header {
  font-size: 22px;
  color: #ffc107;
}
.border-card {
  border-radius: 8px !important;
  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(150, 16, 151, 0.8);
  background: url("../assets/BG-SC1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 10px #ee331d !important ;
}
.text-success {
  color: rgb(66, 161, 10);
}
.text-faild-or-cancel {
  color: red;
}
.text-waitting {
  color: orange;
}
</style>