import axios from "axios";
import config from "../../config/config";
import jwt_decode from "jwt-decode";
import { globalAxios } from "../../../axios-config";
const state = () => ({
  userProfile: [],
  userBalance: [],
  userToken: null,
  sourceEnable: false,
});

const mutations = {
  setSource(state) {
    var source = localStorage.getItem("source");
    if (source != null) {
      let data = JSON.parse(source);
      if (data.source == null) {
      //  console.log("sssss");
        state.sourceEnable = true;
        //ปิด v-select ว่ามาจากไหน กรณีที่มี source
      } else {
       // console.log("xxxxx");
        state.sourceEnable = false;
        //เปิด v-select ว่ามาจากไหน
      }
    } else {
      state.sourceEnable = true;
    }
  },
  setCurrentToken(state) {
    var token = localStorage.getItem("toKen");
    if (token) {
      state.userToken = token;
    }
  },

  setUserToken(state, payload) {
    state.userToken = payload;
  },
  setUser(state, payload) {
    state.userProfile = jwt_decode(payload);
  },
  getCurentUser(state) {
    state.userProfile = jwt_decode(localStorage.getItem("toKen"));
  },
  setUserBalance(state, payload) {
    state.userBalance = payload;
  },
  clearUserDataWhenLogout(state) {
    state.userProfile = [];
  },
};

const actions = {
  async userAuthen({ commit }, body) {
    try {
      let response = await axios({
        method: "post",
        url: `${config.dev.api}/${config.api.user}`,
        data: body,
      });

      localStorage.toKen = response.data.token;
      commit("setUserToken", response.data.token);
      commit("setUser", response.data.token);
      return true;
    } catch (error) {
      return error.response.data.message;
    }
  },
  async userSignUp({ commit }, body) {
    try {
      let response = await axios({
        method: "post",
        url: `${config.dev.api}/${config.api.user}/signup`,
        data: body,
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  async userSources() {
    try {
      let response = await axios({
        method: "get",
        url: `${config.dev.api}/${config.api.user}/settings`,
      });

      return response.data;
    } catch (error) {
      return error.response.data.errors;
    }
  },
  async userGetBalance({ commit }) {
    try {
      let response = await globalAxios({
        method: "get",
        url: `/${config.api.user}/balance `,
      });
      commit("setUserBalance", response.data);
      return response.data;
    } catch (error) {
      return error.response.data.errors;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
