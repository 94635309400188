<template>
  <div>
    <Home />
    <ErrorDialog
      :onShowErrorDialog="showDialog"
      :dataError="dataError"
      @onCancelDialog="onCancelErrorDialog($event)"
    />
  </div>
</template>

<script>
import Home from "../home/Home";
import ErrorDialog from "../../components/ErrorDialog"
export default {
  components: {
    Home,
    ErrorDialog,
  },
  data: () => ({
    showDialog: true,
    dataError: {
      message: "ยังไม่มีข้อมูลสำหรับเนื้อหานี้",
    },
  }),
  methods: {
    onCancelErrorDialog(onCancelErrorDialog) {
      // this.showDialog =[{onShowDialog:false},];
      this.showDialog = onCancelErrorDialog;
     
    },
  },
};
</script>

<style>

</style>