<template>
  <div>
    <alertDialog
      :dialog="dialogAlert"
      :txtMessage="txtMessage"
      @closeAlertDialog="closeAlertDialog($event)"
    />
    <confrmDialog
      :nameBet="nameBet"
      :amount="amount"
      :confirmImg="confirmImg"
      :dialog="dialogConfrm"
      txtMessage="คุณต้องการสร้างห้องนี้ใช่หรือไม่ ?"
      :isLoading="isLoading"
      @closeAlertDialog="closeFrmDialog($event)"
    />
    <v-container
      ><v-row>
        <v-col style="color:white" cols="12"
          ><v-icon color="white" class="pr-1">mdi-plus-circle-outline</v-icon
          >สร้างเกมใหม่</v-col
        >
      </v-row>
      <div class="divider div-transparent my-4"></div>
      <v-col style="color:white" cols="12">ตัวเลือกของท่าน</v-col>
      <v-col cols="12">
        <v-row class="justify-space-around"
          ><v-col
            v-for="(item, index) in rpsSelect"
            :key="index"
            class=""
            cols="5"
            xl="2"
            lg="2"
            md="2"
            @click="selectRPS(item, index)"
          >
            <v-row>
              <v-col :class="item.active ? item.cssActive : item.css" cols="12">
                <v-img class="img" width="50%" :src="item.src"></v-img>
              </v-col>
              <v-col style="color:white" class="d-flex align-center justify-center pt-0" cols="12"
                >{{ item.name }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col style="color:white" cols="12">
        <v-row>
          <v-col cols="12">จำนวนที่เดิมพัน</v-col>
          <v-col
            cols="6"
            xl="4"
            lg="4"
            md="4"
            v-for="(item, index) in betPoint"
            :key="index"
          >
            <v-btn
              block
              :class="
                item.active
                  ? 'd-flex align-center justify-center btn-amount-active'
                  : 'd-flex align-center justify-center btn-amount'
              "
              @click="selectBetPoint(item, index)"
              depressed
              >{{ item.amount }} บาท</v-btn
            ></v-col
          >
        </v-row>
      </v-col>
      <v-col cols="12" class="d-flex align-center justify-center">
        <v-btn
          :loading="loading"
          :disabled="loading"
          @click="createGameRoom(null)"
          class="ma-2"
          :color="this.amount && this.p1bet != null ? '#4CAF50' : '#DADADA'"
          ><v-icon class="pr-1">mdi-plus-circle-outline</v-icon> สร้างเกม
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template></v-btn
        >
      </v-col>
    </v-container>
  </div>
</template>
<script>
import confrmDialog from "../../../alertDialog/game/minigame/rps/confirmDialogSelectRPS.vue";
import { mapState } from "vuex";
import alertDialog from "../../../../components/alertDialog/alertDialog.vue";
export default {
  mounted() {
    this.test();
  },
  components: {
    alertDialog,
    confrmDialog,
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  data: () => ({
    confirmImg: "",
    isLoading: false,
    dialogConfrm: false,
    txtMessage: null,
    dialogAlert: false,
    btnCreateActive: false,
    loader: null,
    loading: false,
    amount: null,
    nameBet: "",
    p1bet: null,
    betPoint: [],
    rpsSelect: [
      {
        name: "ค้อน",
        src: require("../../../../assets/hand-rock.png"),
        active: false,
        value: "r",
        css: "bg-rock d-flex flex-column align-center justify-center cursor_pointer rps-unselect",
        cssActive:
          "bg-rock d-flex flex-column align-center justify-center cursor_pointer rps-select",
      },
      {
        name: "กระดาษ",
        src: require("../../../../assets/hand-paper.png"),
        active: false,
        value: "p",
        css: "bg-paper d-flex flex-column align-center justify-center cursor_pointer rps-unselect",
        cssActive:
          "bg-paper d-flex flex-column align-center justify-center cursor_pointer rps-select",
      },
      {
        name: "กรรไกร",
        src: require("../../../../assets/hand-scissors.png"),
        active: false,
        value: "s",
        css: "bg-scissors d-flex flex-column align-center justify-center cursor_pointer rps-unselect",
        cssActive:
          "bg-scissors d-flex flex-column align-center justify-center cursor_pointer rps-select",
      },
    ],
  }),
  computed: {
    ...mapState({
     userProfile: (state) => state.user.userProfile,
      betPoints: (state) => state.game.betPoint,
    }),
  },
  methods: {
    test() {
      for (let i = 0; i < this.betPoints.datas.length; i++) {
        this.betPoint.push({ amount: this.betPoints.datas[i], active: false });
      }
    },
    closeFrmDialog(isConfrim) {
      this.dialogConfrm = false;
      this.createGameRoom(isConfrim);
    },
    closeAlertDialog() {
      this.dialogAlert = false;
    },
    selectBetPoint(option, index) {
      for (let i = 0; i < this.betPoint.length; i++) {
        this.betPoint[i].active = false;
      }
      this.betPoint[index].active = true;
      this.amount = option.amount;
    },
    selectRPS(option, index) {
      for (let i = 0; i < this.rpsSelect.length; i++) {
        this.rpsSelect[i].active = false;
      }
      this.rpsSelect[index].active = true;
      this.p1bet = option.value;
      this.confirmImg = option.src;
      this.nameBet = option.name;
    },
    async createGameRoom(isConfrim) {
      if (this.userProfile.length == 0) {
        this.dialogAlert = true;
        this.txtMessage = "กรุณาเข้าสู่ระบบก่อนทำรายการ";
        return false;
      } else {
        if (this.p1bet == null || this.amount == null) {
          this.txtMessage = "กรุณาเลือกตัวเลือกให้ครบถ้วน";
          this.dialogAlert = true;
        } else {
          let body = {
            p1bet: this.p1bet,
            amount: this.amount,
          };
          if (isConfrim == true) {
            let res = await this.$store.dispatch("game/postRoomRPS", body);
            if (res.status == true) {
              this.txtMessage = res.response.message;
              this.dialogAlert = true;
            } else {
              this.txtMessage = res.response.message;
              this.dialogAlert = true;
            }
            for (let i = 0; i < this.betPoint.length; i++) {
              this.betPoint[i].active = false;
            }
            for (let i = 0; i < this.rpsSelect.length; i++) {
              this.rpsSelect[i].active = false;
            }
            this.amount = null;
            this.p1bet = null;
          } else if (isConfrim == null) {
            this.dialogConfrm = true;
          } else if (isConfrim == false) {
            this.dialogConfrm = false;
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.div-transparent:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 2px;
  background-image: linear-gradient(
    to right,
    transparent,
    rgb(231, 90, 43),
    transparent
  );
}
.divider {
  position: relative;
  height: 1px;
}
.btn-amount-active {
  background: rgba(55, 2, 139, 0.96) 99.96% !important;
  color: white(206, 149, 25);
  box-shadow: inset 0 0 15px #77a5eb !important;
  border-radius: 10px !important;
  border: 2px solid rgba(155, 225, 255, 0.96) !important;
  width: 100px;
  height: 50px !important;
}
.btn-amount {
  background: rgb(0, 14, 43, 1);
  color: white(206, 149, 25);
  box-shadow: inset 0 0 15px #77a5eb !important;
  border-radius: 10px !important;
  border: 2px solid rgba(155, 225, 255, 0.96) !important;
  width: 100px;
  height: 50px !important;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
.bg-rock {
  border-radius: 8px;
  background-color: #f8de06;
  background-image: -webkit-linear-gradient(
    -28deg,
    #f8de06 0%,
    #f8de06 60%,
    #292423 60%,
    #292423 60%
  );
  background-image: linear-gradient(
    -28deg,
    #f8de06 0%,
    #f8de06 60%,
    #292423 60%,
    #292423 60%
  );
}
.bg-paper {
  border-radius: 8px;
  background-color: #fcaff2;
  background-image: -webkit-linear-gradient(
    -28deg,
    #fcaff2 0%,
    #fcaff2 60%,
    #292423 60%,
    #292423 60%
  );
  background-image: linear-gradient(
    -28deg,
    #fcaff2 0%,
    #fcaff2 60%,
    #292423 60%,
    #292423 60%
  );
}
.bg-scissors {
  border-radius: 8px;
  background-color: #8ee1fc;
  background-image: -webkit-linear-gradient(
    -28deg,
    #8ee1fc 0%,
    #8ee1fc 60%,
    #292423 60%,
    #292423 60%
  );
  background-image: linear-gradient(
    -28deg,
    #8ee1fc 0%,
    #8ee1fc 60%,
    #292423 60%,
    #292423 60%
  );
}
.cursor_pointer {
  cursor: pointer;
}

.cursor_pointer:hover > .img {
  transform: rotate(45deg) scale(1.25);
}

.rps-unselect {
  border: 3.5px solid #1e1e1e;
}
.rps-select {
  border: 3.5px solid #4caf50;
}
.img {
  display: block;
  transition: transform 0.5s;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>