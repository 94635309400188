<template>
  <div>
    <Home />
    <RegisterDialog
      :dialog="showDialog"
      @onCancelDialog="onCancelDialog($event)"
    />
  </div>
</template>
<script>
import Home from "../home/Home";
import RegisterDialog from "../../components/RegisterDialog";
export default {
  mounted() {
    // var mins = 30; // Reset when storage is more than 24hours
    // var now = new Date().getTime();
    //
    // if (setupTime == null) {
    //   localStorage.setItem("setupTime", now);
    // } else {
    //   if (now - setupTime > mins * 1000) {
    //     localStorage.clear();
    //     localStorage.setItem("setupTimess", now);
    //   }
    // }
    var min = 30;
    var now = new Date().getTime();
    var referer = localStorage.getItem("referer");
    if (referer == null) {
      window.localStorage.setItem(
        "referer",
        JSON.stringify({
          referer: this.$route.params.refId,
          // expiredAt: new Date().getTime() + 30 * 60 * 1000,
          expiredAt: now,
        })
      );
    } else {
      if (referer) {
        let data = JSON.parse(referer);
        if (
          data.referer != this.$route.params.refId &&
          this.$route.params.refId != null
        ) {
          window.localStorage.setItem(
            "referer",
            JSON.stringify({
              referer: this.$route.params.refId,
              expiredAt: now,
            })
          );
        }
        if (new Date().getTime() - data.expiredAt > min * 60 * 1000) {
          localStorage.removeItem("referer");
          window.localStorage.setItem(
            "referer",
            JSON.stringify({
              referer: this.$route.params.refId,
              // expiredAt: new Date().getTime() + 30 * 60 * 1000,
              expiredAt: now,
            })
          );
        }
      }
    }
  },
  components: {
    Home,
    RegisterDialog,
  },
  data: () => ({
    showDialog: true,
  }),
  methods: {
    onCancelDialog(onCancelDialog) {
      this.showDialog = onCancelDialog;
    },
  },
};
</script>
<style scoped>
