import { globalAxios } from "../../axios-config"

export default function setup() {
    globalAxios.interceptors.request.use(function (config) {
        const token = localStorage.getItem('toKen')
        if (token) {
            config.headers.Authorization = `${token}`;
        }
        return config;

    }, function (err) {
        return Promise.reject(err);
    });
}