<template>
  <div class="px-4" style="padding-bottom: 140px; padding-top: 30px">
    <v-container class="pb-16 px-lx-16 px-lg-16 section-content-custom">
      <v-row style="margin-top: -43px">
        <v-col
          cols="9"
          lg="4"
          md="4"
          sm="6"
          xl="4"
          class="title-custom text-center"
          >รับเครดิตเงินคืน</v-col
        >
      </v-row>
      <v-row class="pt-16 px-lx-16 px-lg-16 justify-center">
        <v-col
          cols="11"
          lg="10"
          xl="10"
          md="9"
          sm="8"
          class="border-card-detail"
        >
          <v-row>
            <v-col>
              <div class="mt-3 text-center" style="font-size: 20px">
                ประวัติการเล่น 7 วันล่าสุด
              </div>
              <v-container>
                <div class="black mt-4">
                  <v-data-table
                    dark
                    :loading="isLoading"
                    :headers="headersCashBack"
                    :items="userCashBack.datas"
                    class="elevation-1"
                    mobile-breakpoint="0"
                  >
                  </v-data-table></div
              ></v-container>

              <v-container>
                <div class="text-center" style="font-size: 18px">
                  ยอดเงินคืนสะสม
                  <span style="color: gold"
                    >฿{{ userCashBack.availableAmount }}</span
                  >
                </div></v-container
              >
              <v-container>
                <v-btn
                  @click="onGetBonus()"
                  width="100%"
                  class="btn-orange"
                  depressed
                >
                  <div style="color: white" class="pl-3">
                    <v-icon small class="pr-1">mdi-share-all-outline</v-icon>
                    รับเครดิตเงินคืน
                  </div>
                </v-btn></v-container
              >
              <v-container class="pb-8">
                <div class="text-center" style="font-size: 18px">
                  ขั้นต่ำ ฿{{ userCashBack.minimumAmount }} ไม่ต้องทำเทิร์น
                </div>
              </v-container>
              <ErrorDialog
                :dataError="dataError"
                :onShowErrorDialog="showDialog"
                @onCancelDialog="onCancelErrorDialog($event)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import ErrorDialog from "../../components/ErrorDialog";
import { mapState } from "vuex";
export default {
  components: {
    ErrorDialog,
  },
  methods: {
    onCancelErrorDialog(onCancelErrorDialog) {
      // this.showDialog =[{onShowDialog:false},];
      this.showDialog = onCancelErrorDialog;
    },
    async getUserCashBack() {
      this.isLoading = true;
      await this.$store.dispatch("cashback/getUserCashBack");

      this.isLoading = false;
    },
    async onGetBonus() {
      let res = await this.$store.dispatch("cashback/getCashBack");
      if (res.msg) {
        this.showDialog = true;
        this.dataError = res.data;
      } else {
        this.showDialog = true;
        this.dataError = res.data;
      }
    },
  },
  mounted() {
    this.getUserCashBack();
  },
  computed: {
    ...mapState({
      userCashBack: (state) => state.cashback.userCashBack,
    }),
  },
  data: () => ({
    dataError: {},
    showDialog: false,
    isLoading: false,
    headersCashBack: [
      {
        text: "วันที่",
        align: "center",
        value: "date",
        sortable: false,
      },
      {
        text: "ยอดเล่น	",
        align: "center",
        value: "bet",
        sortable: false,
      },
      {
        text: "ยอดเงินคืน 0.3%",
        align: "center",
        value: "amount",
        sortable: false,
      },
    ],
  }),
};
</script>
<style scoped>
.border-card-detail {
  border-radius: 8px;
  opacity: 0.98;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(205, 62, 19, 1);
  background-color: rgba(51, 6, 59, 0.75) !important;
  font-size: 16px;
  color: #404040;

  background-position: center;
  background-attachment: fixed;
}
.title-custom {
  top: -4% !important;
}
</style>