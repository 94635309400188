<template>
  <div>
    <Home />
    <RegisterDialog
      :dialog="showDialog"
      @onCancelDialog="onCancelDialog($event)"
    />
  </div>
</template>
<script>
import Home from "../home/Home";
import RegisterDialog from "../../components/RegisterDialog";
export default {
  mounted() {
    // var mins = 30; // Reset when storage is more than 24hours
    // var now = new Date().getTime();
    //
    // if (setupTime == null) {
    //   localStorage.setItem("setupTime", now);
    // } else {
    //   if (now - setupTime > mins * 1000) {
    //     localStorage.clear();
    //     localStorage.setItem("setupTimess", now);
    //   }
    // }
    var min = 30;
    var referer = localStorage.getItem("referer");
    if (referer) {
      let data = JSON.parse(referer);
     
      if (new Date().getTime() - data.expiredAt > min * 60 * 1000) {
        localStorage.removeItem("referer");
      } else {
        this.refId = data.referer;
      }
    }
  },
  components: {
    Home,
    RegisterDialog,
  },
  data: () => ({
    showDialog: true,
    refId: null,
  }),
  methods: {
    onCancelDialog(onCancelDialog) {
      this.showDialog = onCancelDialog;
     
    },
  },
};
</script>
<style scoped>
