var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{class:_vm.$vuetify.breakpoint.xs ? '' : ''},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{class:_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
            ? 'pr-0 py-0 margin-image-custom'
            : '',style:(_vm.$vuetify.breakpoint.xs ? 'transform: scale(0.95)' : ''),attrs:{"xl":"5","lg":"5","md":"5","sm":"8","cols":"11"},on:{"click":function($event){return _vm.$router.push({ path: '/game' })}}},[_c('v-img',{staticClass:"cursor-hover swing",attrs:{"src":require("../../assets/Banner-01.png")}})],1),_c('v-col',{class:_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
            ? 'pr-0 py-0 margin-image-custom-2 '
            : '',style:(_vm.$vuetify.breakpoint.xs ? 'transform: scale(0.95)' : ''),attrs:{"xl":"5","lg":"5","md":"5","sm":"8","cols":"11"},on:{"click":function($event){return _vm.$router.push({ path: '/game' })}}},[_c('v-img',{staticClass:"cursor-hover swing",attrs:{"src":require("../../assets/Banner-02.png")}})],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{class:_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
            ? 'pr-0 py-0 margin-image-custom'
            : '',style:(_vm.$vuetify.breakpoint.xs ? 'transform: scale(0.95)' : ''),attrs:{"xl":"5","lg":"5","md":"5","sm":"8","cols":"11"},on:{"click":function($event){return _vm.$router.push({ path: '/game' })}}},[_c('v-img',{staticClass:"cursor-hover swing",attrs:{"src":require("../../assets/Banner-03.png")}})],1),_c('v-col',{class:_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
            ? 'pr-0 py-0 margin-image-custom-2'
            : '',style:(_vm.$vuetify.breakpoint.xs ? 'transform: scale(0.95)' : ''),attrs:{"xl":"5","lg":"5","md":"5","sm":"8","cols":"11"},on:{"click":function($event){return _vm.$router.push({ path: '/game' })}}},[_c('v-img',{staticClass:"cursor-hover swing",attrs:{"src":require("../../assets/Banner-04.png")}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }