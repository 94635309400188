import axios from "axios";
import config from "././src/config/config";

export const globalAxios = axios.create({
  baseURL: config.dev.api,
});

globalAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // console.log(error.response, "errorrrrrr");
    if (error.response.data.refresh == true) {
      // console.log("logout");
      localStorage.removeItem("toKen");
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);
