<template>
  <div style="background-color: #3e133a">
    <v-row
      ><v-col>
        <v-container>
          <div
            class="text-start text-promotion-header"
            style="text-shadow: 0px 1px 3px #1a0216"
          >
            ระบบถอนเงินอัตโนมัติ
          </div>
          <div style="color: white" class="text-center">
            จำนวนเครดิตทั้งหมด:฿{{ userbalance.balance }}
            <div class="border-card-bank2 mt-3 mb-5">
              <v-row class="px-3 py-2 justify-center">
                <v-col
                  @click="changeActive(index)"
                  v-for="(item, index) in withdrawData"
                  :key="index"
                  cols="6"
                  class="px-1"
                >
                  <v-btn
                    :class="
                      item.value == true
                        ? 'withdraw-btn-active pa-1'
                        : 'withdraw-btn-unactive pa-1'
                    "
                    block
                    depressed
                  >
                    {{ item.text }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="activeSpecify == true" class="justify-center">
                <v-col cols="11">
                  <div class="text-start">ระบุจำนวนเครดิต:</div>
                  <div>
                    <v-text-field
                      @input="checkActiveWithDraw"
                      v-model="price"
                      prepend-inner-icon="mdi-cash-multiple"
                      suffix="฿"
                      dark
                      color="green"
                      type="number"
                      label="จำนวนที่ต้องการถอน"
                      :rules="rules"
                      hide-details="auto"
                    ></v-text-field>
                    <div class="text-start mt-3">
                      ถอนเงินขั้นต่ำ ฿300 สูงสุดไม่เกิน ฿2,000,000 ต่อครั้ง
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-btn
                @click="activeWithDraw(price)"
                :class="
                  withDrawActiveText == true
                    ? 'withdraw-btn-active mt-3'
                    : 'withdraw-btn-unactive mt-3 '
                "
                block
                depresseds
              >
                ถอน
              </v-btn>
              <v-divider
                class="mt-6 mb-3"
                style="background-color: #ffc107"
              ></v-divider>
              <div>
                <div class="d-flex pt-3">
                  <v-icon class="pr-1" style="color: white"
                    >mdi-format-list-bulleted-square</v-icon
                  >
                  <h4 style="color: white">ข้อจำกัด & ประกาศ</h4>
                </div>
                <ul
                  class="pt-1 text-start"
                  v-for="(item, index) in conditionWithdrawData"
                  :key="index"
                >
                  <li style="font-size: 15px">{{ item.text }}</li>
                </ul>
              </div>
            </div>
          </div>
        </v-container>
      </v-col>
    </v-row>
    <ErrorDialog
      :dataError="dataError"
      :onShowErrorDialog="showDialogError"
      @onCancelDialog="onCancelErrorDialog($event)"
    />
    <SuccessDialog
      :dataSuccess="dataSuccess"
      :onShowSuccessDialog="showDialogSuccess"
      @onCancelDialog="onCancelSuccessDialog($event)"
    />
    <ConfirmDialog
      :dataConfirmDialog="dataConfirmDialog"
      :onShowDialog="showDialogConfirm"
      @onCancelConfirmDialog="onCancelConfirmDialog($event)"
    />
  </div>
</template>

<script>
import SuccessDialog from "./SuccessDialog.vue";
import ConfirmDialog from "./ConfirmDialog.vue";
import ErrorDialog from "./ErrorDialog.vue";
import { mapState } from "vuex";
export default {
  components: {
    SuccessDialog,
    ConfirmDialog,
    ErrorDialog,
  },
  data: () => ({
    showDialogSuccess: false,
    showDialogError: false,
    showDialogConfirm: false,
    dataConfirmDialog: { title: "ยืนยันการถอนใช่หรือไม่?" },
    dataError: {},
    dataSuccess: {},
    rules: [
      (value) => !!value || "โปรดระบุจำนวนเครดิต.",
      (value) => (value && value.length >= 3) || "ขั้นต่ำ ฿300",
    ],
    withDrawActive: false,
    withDrawActiveText: false,
    activeSpecify: false,
    price: null,
    withdrawData: [
      {
        text: "฿300",
        value: false,
        amount: "300",
      },
      {
        text: "฿500",
        value: false,
        amount: "500",
      },
      { text: "฿1,000", value: false, amount: "1000" },
      {
        text: "฿1,500",
        value: false,
        amount: "1500",
      },
      {
        text: "฿3,000",
        value: false,
        amount: "3000",
      },
      {
        text: "฿5,000",
        value: false,
        amount: "5000",
      },
      { text: "฿10,000", value: false, amount: "10000" },
      {
        text: "เลือกจำนวนเครดิต",
        value: false,
        specify: 0,
      },
    ],
    conditionWithdrawData: [
      {
        text: "ครั้งละไม่เกิน 2,000,000 บาท",
      },
      {
        text: "ถอนเงินขั้นต่ำ 300 บาทขึ้นไป",
      },
    ],
  }),
  computed: {
    ...mapState({
      userbalance: (state) => state.user.userBalance,
      userProfile: (state) => state.user.userProfile,
    }),
  },
  methods: {
    onCancelErrorDialog(onCancelErrorDialog) {
      // this.showDialog =[{onShowDialog:false},];
      this.showDialogError = onCancelErrorDialog;
    },
    onCancelSuccessDialog(onCancelSuccessDialog) {
      // this.showDialog =[{onShowDialog:false},];
      this.showDialogSuccess = onCancelSuccessDialog;
    },

    async onCancelConfirmDialog(onCancelConfirmDialog) {
      this.showDialogConfirm = onCancelConfirmDialog.isOpen;
      if (onCancelConfirmDialog.onAccept == true) {
        this.loadingwithdraw = true;
        let body = {
          amount: this.price,
        };
        let res = await this.$store.dispatch("transection/withDraw", body);
        if (res.msg == true) {
          this.showDialogSuccess = true;
          this.dataSuccess = res.data;
        } else {
          this.showDialogError = true;
          this.dataError = res.data;
        }
        this.loadingwithdraw = false;
      }
    },
    changeActive(index) {
      for (let i = 0; i < this.withdrawData.length; i++) {
        this.withdrawData[i].value = false;
        this.withDrawActiveText = true;
      }
      this.withdrawData[index].value = true;
      this.price = this.withdrawData[index].amount;

      if (this.withdrawData[index].specify == 0) {
        this.activeSpecify = true;
        this.withDrawActiveText = false;
      } else {
        this.activeSpecify = false;
      }
    },
    checkActiveWithDraw() {
      if (this.price.length >= 3) {
        this.withDrawActiveText = true;
      } else {
        this.withDrawActiveText = false;
      }
    },
    async activeWithDraw(amount) {
      if (amount == null) {
        this.showDialog = true;
        this.dataError = { message: "โปรดระบุจำนวนเงินที่ต้องการถอน" };
      } else {
        this.showDialogConfirm = true;
      }
    },
    onCancelDialog() {
      this.$emit("onCancelDialog", false);
      for (let i = 0; i < this.withdrawData.length; i++) {
        this.withdrawData[i].value = false;
        this.withDrawActiveText = false;
      }
    },
  },
};
</script>

<style scoped>
.withdraw-btn-active {
  background: #f3bb2f;
  background-image: -webkit-linear-gradient(top, #f3bb2f, #ee331d);
  background-image: -moz-linear-gradient(top, #f3bb2f, #ee331d);
  background-image: -ms-linear-gradient(top, #f3bb2f, #ee331d);
  background-image: -o-linear-gradient(top, #f3bb2f, #ee331d);
  background-image: linear-gradient(to bottom, #f3bb2f, #ee331d);
  -webkit-border-radius: 5;
  -moz-border-radius: 5;
  border-radius: 5px;
  text-shadow: 0px 1px 3px #1a0216;
  -webkit-box-shadow: 0px 0px 10px #9b3bc7;
  -moz-box-shadow: 0px 0px 10px #9b3bc7;
  box-shadow: 0px 0px 10px #9b3bc7;
  color: #ffffff;
  font-size: 14px;
  padding: 4px 20px 4px 20px;
  border-top: solid #ce55cc 1px;
  border-right: solid #520c49 1px;
  border-bottom: solid #ce55cc 1px;
  border-left: solid #520c49 1px;
  text-decoration: none;
}

.withdraw-btn-active:hover {
  background: #ee331d;
  background-image: -webkit-linear-gradient(top, #ee331d, #f3bb2f);
  background-image: -moz-linear-gradient(top, #ee331d, #f3bb2f);
  background-image: -ms-linear-gradient(top, #ee331d, #f3bb2f);
  background-image: -o-linear-gradient(top, #ee331d, #f3bb2f);
  background-image: linear-gradient(to bottom, #ee331d, #f3bb2f);
  text-decoration: none;
}
.withdraw-btn-unactive {
  background: #bb1abe;
  background-image: -webkit-linear-gradient(top, #bb1abe, #45012f);
  background-image: -moz-linear-gradient(top, #bb1abe, #45012f);
  background-image: -ms-linear-gradient(top, #bb1abe, #45012f);
  background-image: -o-linear-gradient(top, #bb1abe, #45012f);
  background-image: linear-gradient(to bottom, #bb1abe, #45012f);
  -webkit-border-radius: 5;
  -moz-border-radius: 5;
  border-radius: 5px;
  text-shadow: 0px 1px 3px #1a0216;
  -webkit-box-shadow: 0px 0px 8px #bc492c;
  -moz-box-shadow: 0px 0px 8px #bc492c;
  box-shadow: 0px 0px 8px #bc492c;

  color: #ffffff;
  font-size: 14px;
  padding: 4px 20px 4px 20px;
  border-top: solid #f7ecff 1px;
  border-right: solid #f9750e 1px;
  border-bottom: solid #f7ecff 1px;
  border-left: solid #f9750e 1px;
  text-decoration: none;
}
.withdraw-btn-unactive:hover {
  background: #45012f;
  background-image: -webkit-linear-gradient(top, #45012f, #bb1abe);
  background-image: -moz-linear-gradient(top, #45012f, #bb1abe);
  background-image: -ms-linear-gradient(top, #45012f, #bb1abe);
  background-image: -o-linear-gradient(top, #45012f, #bb1abe);
  background-image: linear-gradient(to bottom, #45012f, #bb1abe);
  text-decoration: none;
}
.text-title {
  font-size: 22px;
  font-weight: bold;
  background: #cf0000;
  background: -webkit-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: -moz-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.border-card-xs {
  padding: 1rem;
  color: white !important;
  background-color: #321a04 !important;
}

.border-card-bank1 {
  opacity: 0.98;
  padding: 1rem;
  color: white !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  background-image: linear-gradient(
    to bottom right,
    #d1d1d1 0% 65%,
    #b3b3b3 95% 100%
  );
}
.border-card-bank2 {
  border-radius: 8px !important;
  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(150, 16, 151, 0.8);
  background: url("../assets/BG-SC1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 10px #ee331d !important ;
}
.border-card-bank-user {
  box-shadow: 0px 1rem 1.5rem rgba(0, 0, 0, 0.3);
  border-color: #ffc107;
  opacity: 0.98;
  padding: 1rem;
  color: white !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  background-image: linear-gradient(
    to bottom right,
    #000000 0% 65%,
    #141414 95% 100%
  );
}
.text-promotion-header {
  font-size: 22px;
  color: #ffc107;
}
.btn-signin {
  border-radius: 8px;
  background: linear-gradient(90deg, #805e17 0%, #f6c52d 100%);
}
</style>