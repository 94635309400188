import { globalAxios } from "../../../axios-config";
import config from "../../config/config";
const state = () => ({
  userCashBack: [],
});

const mutations = {
  setUserCashBack(state, payload) {
    state.userCashBack = payload;
  },
};

const actions = {
  async getUserCashBack({ commit }) {
    try {
      let response = await globalAxios({
        method: "get",
        url: `/${config.api.ranking}/cashback`,
      });
   
      if (response != null) {
        commit("setUserCashBack", response.data);
      }
      return true;
    } catch (error) {
    
      return error.response.data.errors;
    }
  },
  async getCashBack({ commit }) {
    try {
      let response = await globalAxios({
        method: "post",
        url: `/${config.api.ranking}/cashback`,
      });
      return { msg: true, data: response };
    } catch (error) {
      return { msg: false, data: error.response.data };
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
