<template>
  <v-app class="my_app bg-main">
    <loadingFullPage v-if="waitRender" />
    <div v-else>
      <Header />
    
        <a :href="settingCheck" target="_blank" class="contact-line">
          <div>
            <v-img
              width="65px"
              src="./assets/ez-slot-ic-contact-line.png"
            ></v-img>
          </div>
          <div
            v-if="$vuetify.breakpoint.name != 'xs'"
            class="text-center contact-wrap"
          >
            ติดต่อเรา
          </div>
        </a>
     

      <vue-page-transition name="fade-in-right">
        <router-view></router-view>
      </vue-page-transition>

      <BottomNavigator />
      <v-btn
        :class="
          $vuetify.breakpoint.xs
            ? 'mb-15 transition-swing'
            : 'transition-swing mb-3'
        "
        v-scroll="onScroll"
        v-show="fab"
        fab
        fixed
        bottom
        right
        color="gold"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up-thick</v-icon>
      </v-btn>
      <NewsDialog
        v-if="newsItems"
        :dataNews="newsItems"
        :onShowNewsDialog="showNewsDialog"
        @onCancelNewsDialog="onCancelNewsDialog($event)"
      />
    </div>
  </v-app>
</template>

<script>
import loadingFullPage from "./components/LoadingPage.vue";
import Footer from "./components/Footer.vue";
import NewsDialog from "./components/NewsDialog.vue";
import Snow from "./components/Snow";
import Header from "./components/Header";
import BottomNavigator from "./components/BottomNavigation";
import { mapState } from "vuex";

export default {
  watch: {
    // loadingPercent(val) {
    //   if (val >= 100) {
    //     console.log("complete");
    //     clearInterval(this.interval);
    //   }
    // },
  },
  computed: {
      settingCheck() {
      if (this.LineSetting) {
        let obj = this.LineSetting.find((val) => val.setting === "LINE");
        return obj ? obj.value : "";
      }
    },
    ...mapState({
      LineSetting: (state) => state.setting.LineSetting,
      newsList: (state) => state.news.newsList,
    }),
    // loaded() {
    //   return this.loadingPercent + "%";
    // },
  },
  methods: {
  
    // doProgress() {
    //   let step = this.loadTime / 100;
    //   this.interval = setInterval(() => {
    //     this.loadingPercent++;
    //   }, step);
    // },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },

    onCancelNewsDialog(payload) {
      this.showNewsDialog = false;
      if (payload) {
        this.$store.commit("news/updateNewsList", payload);
      }
      setTimeout(() => {
        this.countIndex++;
        this.onShowNews();
      }, 300);
    },
    onShowNews() {
      let disableNewsList = localStorage.getItem("newsList");

      if (disableNewsList) {
        disableNewsList = JSON.parse(disableNewsList);
      }
      if (this.newsList.length != this.countIndex); // loop data
      let data = this.newsList[this.countIndex];
      if (data) {
        //check function
        if (disableNewsList && !disableNewsList.includes(data.id)) {
          this.newsItems = data;
          this.showNewsDialog = true;
        } else if (!disableNewsList) {
          this.newsItems = data;
          this.showNewsDialog = true;
        } else if (disableNewsList && disableNewsList.includes(data.id)) {
          this.countIndex++;
          this.onShowNews();
        }
        //end check function
      } //end loop data
    },
  },
  data() {
    return {
      waitRender: true,
      fab: false,
      showNewsDialog: false,
      countIndex: 0,
      newsItems: {},
      loadingPercent: 0,
      loadTime: 0,
      interval: null,
    };
  },
  async mounted() {
    this.$nextTick(() => {
      this.waitRender = false;
    });
    // let perfData = window.performance.timing;
    // let estimatedTime = Math.abs(
    //   perfData.loadEventEnd - perfData.navigationStart
    // );
    // this.loadTime = parseInt((estimatedTime / 1000) % 60) * 100;
    // this.doProgress();
    await this.$store.dispatch("setting/getSetting");
    await this.$store.dispatch("news/getAnnounce");
    //this.$store.commit("news/setNewsList");
    if (
      this.$route.path == "/" ||
      this.$route.path == "/*" ||
      this.$route.path == "/ranking" ||
      this.$route.path == "/game" ||
      this.$route.path == "/promotion" ||
      this.$route.path == "/register" ||
      this.$route.path == "/blog" ||
      this.$route.path == "/invite" ||
      this.$route.path == "/cashback"
    ) {
      this.onShowNews();
    }

    this.$store.commit("user/setCurrentToken");
  },
  created() {
    var min = 30;
    var now = new Date().getTime();
    var source = localStorage.getItem("source");
    if (source == null) {
      if (this.$route.query.s != null) {
        window.localStorage.setItem(
          "source",
          JSON.stringify({
            source: this.$route.query.s,
            expiredAt: now,
          })
        );
      }
    } else {
      if (source) {
        let data = JSON.parse(source);
        if (data.source != this.$route.query.s && this.$route.query.s != null) {
          window.localStorage.setItem(
            "source",
            JSON.stringify({
              source: this.$route.query.s,
              expiredAt: now,
            })
          );
        }
        if (new Date().getTime() - data.expiredAt > min * 60 * 1000) {
          localStorage.removeItem("source");
          window.localStorage.setItem(
            "source",
            JSON.stringify({
              source: this.$route.query.s,
              expiredAt: now,
            })
          );
          this.$store.commit("user/setSource");
        }
      }
    }
  },

  components: {
    Snow,
    Header,
    BottomNavigator,
    NewsDialog,
    Footer,
    loadingFullPage,
  },
};
</script>


<style>
::-webkit-scrollbar {
  display: none;
}

@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@200;300;500;700&display=swap");

* {
  font-family: "Kanit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media only screen and (max-width: 600px) {
  .v-label-custom .v-label {
    font-size: 15px !important ;
    color: #5b5d69;
    font-weight: 400;
  }
}

#nav {
  padding: 30px;
}

.theme--light.v-tabs-items {
  background-color: #3e133a;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}
.v-toolbar__content,
.v-toolbar__extension {
  padding: 0 !important;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.v-label-custom .v-label {
  font-size: 17px;
  color: #662482;
  font-weight: 300;
  letter-spacing: 1px;
}
.my_app {
  background: black !important;
}
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .title-custom {
    font-size: 30px !important;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .title-custom {
    font-size: 30px !important;
  }
}
@media only screen and (min-width: 426px) and (max-width: 768px) {
  .title-custom {
    font-size: 30px !important;
  }
}

@media only screen and (min-width: 600px) {
  .bg-main {
    background-image: url("./assets/BG-02.png") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative;
    min-width: 100%;
    min-height: 100%;
  }
}
@media only screen and (min-width: 376px) and (max-width: 425px) {
  .title-custom {
    font-size: 1.6em !important;
  }
  .section-content-custom {
    box-shadow: inset 1px -1px 7px 7px #d7400d, 1px -1px 7px 7px #d7400d !important;
  }
}
@media only screen and (min-width: 321px) and (max-width: 375px) {
  .title-custom {
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 320px) {
  .title-custom {
    font-size: 1em !important;
  }
}

.bg-main {
  background-image: url("./assets/BG-Home.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: relative;
  min-width: 100%;
  min-height: 100%;
}

.section-content-custom {
  border: 11px double #ffffff;
  border-radius: 20px 20px 20px 20px;
  box-shadow: inset 1px -1px 13px 9px #d7400d, 1px -1px 13px 9px #d7400d;
  background: rgba(57, 7, 56, 0.6);
  position: relative;
}
.title-custom {
  text-shadow: 2px 2px #000000;
  color: #ffeb00;
  padding-right: 25px;
  padding-left: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 30px;
  transform: translateX(-50%);
  position: relative;
  left: 50%;
  border-radius: 30px 30px 30px 30px;
  background: linear-gradient(
    180deg,
    rgba(196, 40, 150, 1) 0%,
    rgba(24, 0, 15, 1) 100%
  );
  box-shadow: 1px -1px 13px 11px rgba(229, 69, 5, 0.75);
  -webkit-box-shadow: 1px -1px 13px 11px rgba(229, 69, 5, 0.75);
  -moz-box-shadow: 1px -1px 13px 11px rgba(229, 69, 5, 0.75);
}

.btn-purple {
  background: #bb1abe;
  background-image: -webkit-linear-gradient(top, #bb1abe, #45012f);
  background-image: -moz-linear-gradient(top, #bb1abe, #45012f);
  background-image: -ms-linear-gradient(top, #bb1abe, #45012f);
  background-image: -o-linear-gradient(top, #bb1abe, #45012f);
  background-image: linear-gradient(to bottom, #bb1abe, #45012f);
  -webkit-border-radius: 5;
  -moz-border-radius: 5;
  border-radius: 5px;
  text-shadow: 0px 1px 3px #1a0216;
  -webkit-box-shadow: 0px 0px 8px #bc492c;
  -moz-box-shadow: 0px 0px 8px #bc492c;
  box-shadow: 0px 0px 8px #bc492c;

  color: #ffffff;
  font-size: 20px;
  padding: 4px 20px 4px 20px;
  border-top: solid #f7ecff 1px;
  border-right: solid #f9750e 1px;
  border-bottom: solid #f7ecff 1px;
  border-left: solid #f9750e 1px;
  text-decoration: none;
}

.btn-purple:hover {
  background: #45012f;
  background-image: -webkit-linear-gradient(top, #45012f, #bb1abe);
  background-image: -moz-linear-gradient(top, #45012f, #bb1abe);
  background-image: -ms-linear-gradient(top, #45012f, #bb1abe);
  background-image: -o-linear-gradient(top, #45012f, #bb1abe);
  background-image: linear-gradient(to bottom, #45012f, #bb1abe);
  text-decoration: none;
}

.btn-orange {
  background: #f3bb2f;
  background-image: -webkit-linear-gradient(top, #f3bb2f, #ee331d);
  background-image: -moz-linear-gradient(top, #f3bb2f, #ee331d);
  background-image: -ms-linear-gradient(top, #f3bb2f, #ee331d);
  background-image: -o-linear-gradient(top, #f3bb2f, #ee331d);
  background-image: linear-gradient(to bottom, #f3bb2f, #ee331d);
  -webkit-border-radius: 5;
  -moz-border-radius: 5;
  border-radius: 5px;
  text-shadow: 0px 1px 3px #1a0216;
  -webkit-box-shadow: 0px 0px 10px #9b3bc7;
  -moz-box-shadow: 0px 0px 10px #9b3bc7;
  box-shadow: 0px 0px 10px #9b3bc7;
  color: #ffffff;
  font-size: 20px;
  padding: 4px 20px 4px 20px;
  border-top: solid #ce55cc 1px;
  border-right: solid #520c49 1px;
  border-bottom: solid #ce55cc 1px;
  border-left: solid #520c49 1px;
  text-decoration: none;
}

.btn-orange:hover {
  background: #ee331d;
  background-image: -webkit-linear-gradient(top, #ee331d, #f3bb2f);
  background-image: -moz-linear-gradient(top, #ee331d, #f3bb2f);
  background-image: -ms-linear-gradient(top, #ee331d, #f3bb2f);
  background-image: -o-linear-gradient(top, #ee331d, #f3bb2f);
  background-image: linear-gradient(to bottom, #ee331d, #f3bb2f);
  text-decoration: none;
}

.main-logo {
  animation: shake 8s;
  animation-iteration-count: infinite;
}
.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
.invert {
  filter: invert(100%);
}
.contact-line {
  position: fixed;
  bottom: 135px;
  right: 10px;
  transition: transform 250ms;
  z-index: 99;
}
.contact-line:hover {
  cursor: pointer;
  transform: translateY(-20px);
}
.contact-wrap {
  border-radius: 15px;
  background-color: white;
  color: black;
  font-size: 14px;
}
</style>
