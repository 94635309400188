<template>
  <div>
    <GameLoadingDialog :loginDialog="loginDialog" :txtMessage="txtMessage" />
  </div>
</template>
<script>
import GameLoadingDialog from "../../components/GameLoadingDialog";
export default {
  mounted() {
    this.onGetGameURL(
      this.$route.params.gameTag,
      this.$route.params.gameType,
      this.$route.params.token,
      this.$route.params.subGame
    );
  },
  methods: {
    async onGetGameURL(gameTag, gameType, token, subGame) {
      let data = {
        gameTag,
        gameType,
        body: {
          "x-game-token": token ? token : null,
          "x-game-code": subGame ? subGame : null,
        },
      };
     
      let res = await this.$store.dispatch("game/getGameURL", data);
      if (res.status == true) {
        setTimeout(() => {
          window.location.href = `${res.response}`;
        }, 3000);
        return;
      } else {
        this.txtMessage = res.response.message;
        setTimeout(() => {
          window.close();
        }, 5000);
      }
    },
  },
  components: {
    GameLoadingDialog,
  },
  data: () => ({
    txtMessage: null,
    loginDialog: true,
    dialog: false,
    timerID: "",
  }),
};
</script>
<style scoped>
</style>