<template>
  <v-alert
  class="mb-0"
    :value="alert.onAlert"
    color="red"
    dark
    border="top"
    icon="mdi-alert"
    transition="slide-y-transition"
  >
    {{ alert.text }}
  </v-alert>
</template>
<script>
export default {
  props: ["alert"],
  watch: {
    alert: {
      handler: function () {
        setTimeout(() => {
          this.alert.onAlert = false;
        }, 2000);
      },
      deep: true,
    },
  },
};
</script>

