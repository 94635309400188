<template>
  <v-col class="justify-lg-space-between justify-md-space-between pt-13">
    <v-row>
      <ErrorDialog
        :dataError="dataError"
        :onShowErrorDialog="showDialogErrorLogin"
        @onCancelDialog="onCancelErrorDialog($event)"
      />
      <RegisterDialog
        :dialog="showDialogRegister"
        @onCancelDialog="onCancelDialogRegister($event)"
      />
      <ConfirmDialog
        :dataConfirmDialog="dataConfirmDialog"
        :onShowDialog="showDialogConfirm"
        @onCancelConfirmDialog="onCancelConfirmDialog($event)"
      />
      <v-col
        cols="12"
        sm="12"
        md="3"
        lg="3"
        xl="3"
        class="d-flex flex-column align-center"
      >
        <v-img
          @click="$router.push({ path: '/' })"
          :width="
            $vuetify.breakpoint.xl ||
            $vuetify.breakpoint.lg ||
            $vuetify.breakpoint.md
              ? ''
              : '65% '
          "
          class="bounce main-logo"
          src="../assets/logo.png"
        >
        </v-img>
      </v-col>
      <v-col cols="12" sm="12" md="9" lg="9" xl="9">
        <v-row>
          <v-col cols="12">
            <v-row
              class="
                justify-xl-end
                justify-lg-end
                justify-sm-center
                justify-md-end
                justify-center
                xl-pt-6
                lg-pt-6
                px-3
              "
            >
              <v-col cols="12" sm="10" md="8" lg="6" xl="5">
                <v-row v-if="userProfile.length != 0">
                  <v-col cols="6" xl="7" lg="7" md="7" sm="7">
                    <v-row>
                      <v-col>
                        <v-row class="test">
                          <v-col
                            cols="12"
                            class="d-flex flex-column align-end py-1 px-3"
                          >
                            <div
                              class="text-credit-custom-1"
                              style="
                                color: white;
                                text-shadow: 0px 0px 2px #1a0216;
                              "
                            >
                              เครดิตของคุณ
                            </div>
                            <div class="text-credit-custom-2">
                              ฿{{ userBalance.balance }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12" class="px-0 pt-1 pb-0">
                        <v-btn
                          class="btn-purple"
                          block
                          depressed
                          @click="$router.push({ path: '/transaction' })"
                        >
                          <div style="color: white; font-size: 1.2rem">
                            ฝาก - ถอน
                          </div>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="6"
                    xl="5"
                    lg="5"
                    sm="5"
                    md="5"
                    class="d-flex flex-column"
                  >
                    <v-row>
                      <v-col cols="12" class="pa-0 pl-1 pb-1">
                        <v-btn
                          class="btn-purple"
                          @click="$router.push({ path: '/invite' })"
                          block
                          depressed
                          height="100%"
                        >
                          <div style="color: white" class="">แนะนำเพื่อน</div>
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="pa-0 pl-1 pb-1">
                        <v-btn
                          class="btn-purple"
                          @click="$router.push({ path: '/cashback' })"
                          block
                          depressed
                          height="100%"
                        >
                          <div style="color: white" class="btn-login-text">
                            รับเครดิตเงินคืน
                          </div>
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="pa-0 pl-1">
                        <v-btn
                          class="logout-custom"
                          block
                          depressed
                          height="100%"
                          @click.stop="showDialogConfirm = true"
                        >
                          <v-icon color="white">mdi-logout-variant</v-icon>
                          <div style="color: white" class="pl-2">
                            ออกจากระบบ
                          </div>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12" sm="7" md="7" lg="7" xl="7">
                    <v-row>
                      <v-col
                        cols="2"
                        class="
                          d-flex
                          mt-1
                          mb-1
                          my-xl-1 my-lg-1 my-md-1 my-sm-1
                          form-icon-style
                          justify-center
                          align-center
                        "
                      >
                        <v-icon
                          :size="$vuetify.breakpoint.xl ? '25px' : ''"
                          color="#662482"
                          >mdi-account-circle</v-icon
                        >
                      </v-col>
                      <v-col
                        cols="10"
                        class="
                          px-0
                          pb-1
                          pt-1
                          mt-xl-0
                          py-xl-1 py-lg-1 py-lg-1
                          mt-md-0
                          py-md-1 py-sm-1 py-sm-1
                        "
                      >
                        <v-text-field
                          background-color="#fff7ea"
                          type="number"
                          class="v-label-custom"
                          hide-details
                          label="เบอร์โทรศัพท์"
                          flat
                          solo
                          v-model="userTelNumber"
                          single-line
                          style="
                            border-top-left-radius: 0px;
                            border-bottom-left-radius: 0px;
                            border-top-right-radius: 4px;
                            border-bottom-right-radius: 4px;
                          "
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="2"
                        class="
                          d-flex
                          mt-1
                          mb-1
                          my-xl-1 my-lg-1 my-md-1 my-sm-1
                          form-icon-style
                          justify-center
                          align-center
                        "
                      >
                        <v-icon
                          :size="$vuetify.breakpoint.xl ? '25px' : ''"
                          color="#662482"
                          >mdi-lock-open-variant</v-icon
                        >
                      </v-col>
                      <v-col
                        cols="10"
                        class="
                          px-0
                          pb-1
                          pt-1
                          mt-xl-0
                          py-xl-1 py-lg-1
                          mt-md-0
                          py-md-1
                          mt-sm-0
                          py-sm-1
                        "
                      >
                        <v-text-field
                          background-color="#fff7ea"
                          type="password"
                          class="v-label-custom"
                          hide-details
                          label="รหัสผ่าน"
                          flat
                          solo
                          v-model="passWord"
                          single-line
                          style="
                            border-top-left-radius: 0px;
                            border-bottom-left-radius: 0px;
                            border-top-right-radius: 4px;
                            border-bottom-right-radius: 4px;
                          "
                        ></v-text-field
                      ></v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="5" md="5" lg="5" xl="5">
                    <v-row>
                      <v-col
                        cols="6"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        class="py-1 px-1 pl-0 pl-lg-1 pl-sm-1 pr-sm-0"
                      >
                        <button
                          @click="onLogin()"
                          style="width: 100%; height: 48px"
                          class="btn-purple"
                        >
                          <v-icon class="pr-1" color="white"
                            >mdi-login-variant</v-icon
                          >เข้าสู่ระบบ
                        </button>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        class="py-1 px-1 pr-0 pl-lg-1"
                      >
                        <button
                          @click.stop="showDialogRegister = true"
                          style="width: 100%; height: 48px"
                          class="btn-orange"
                        >
                          <v-icon class="pr-1" color="white"
                            >mdi-account-plus-outline</v-icon
                          >สมัครสมาชิก
                        </button>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="
              $vuetify.breakpoint.xl ||
              $vuetify.breakpoint.lg ||
              $vuetify.breakpoint.md
            "
            class="mt-6"
            cols="12"
          >
            <div
              class="
                d-flex
                justify-space-between
                align-space-between
                navbar-active
              "
            >
              <router-link to="/" class="top-menu"
                ><div class="px-2">หน้าแรก</div>
              </router-link>
              <router-link
                v-if="userProfile.length == 0"
                to="/register"
                class="top-menu"
                ><div class="px-2">สมัครสมาชิก</div>
              </router-link>
              <router-link to="/game" class="top-menu"
                ><div class="px-2">เล่นเกม</div>
              </router-link>
              <router-link to="/promotion" class="top-menu"
                ><div class="px-2">โปรโมชั่น</div>
              </router-link>
              <router-link to="/ranking" class="top-menu"
                ><div class="px-2">รางวัลประจำเดือน</div>
              </router-link>
              <router-link to="/manual" class="top-menu"
                ><div class="px-2">คู่มือ</div>
              </router-link>
              <router-link to="/blog" class="top-menu"
                ><div class="px-2">บทความ</div>
              </router-link>
              <a class="top-menu" :href="settingCheck" target="_blank">
                ติดต่อเรา
              </a>
            </div>
          </v-col>
          <v-col v-else>
            <div>
              <v-app-bar
                style="background-color: #ea848400"
                dense
                fixed
                elevation="0"
              >
                <v-row class="tab-custom pb-0 px-4">
                  <v-col cols="3" class="pl-0 d-flex justify-start pb-0">
                    <v-app-bar-nav-icon
                      color="white"
                      large
                      @click.stop="drawer = !drawer"
                    ></v-app-bar-nav-icon
                  ></v-col>
                  <v-col
                    v-if="userProfile.length != 0"
                    class="d-flex justify-end align-center pb-0"
                  >
                    <div style="color: white">{{ userProfile.username }}</div>
                    <div class="pl-1">
                      <v-icon size="25" style="color: gold">mdi-cash</v-icon>
                    </div>
                    <div style="color: gold; font-size: 18px">
                      {{ userBalance.balance }}
                    </div>
                    <div
                      class="pl-1"
                      style="
                        color: white;
                        font-size: 15px;
                        text-shadow: 2px 2px #531f6a;
                      "
                    >
                      ({{ userBalance.currentTurnover }}/{{
                        userBalance.targetTurnover
                      }})
                    </div>
                  </v-col>
                </v-row>
              </v-app-bar>
              <v-navigation-drawer
                color="rgb(148,18,147,0.9)"
                v-model="drawer"
                fixed
                temporary
              >
                <div v-if="userProfile.length != 0">
                  <v-list nav dense>
                    <v-list-item-group
                      v-model="group"
                      active-class="white--text text--accent-4"
                    >
                      <v-list-item link @click="$router.push({ path: '/' })">
                        <v-list-item-title class="text-start align-center"
                          ><v-icon class="mr-3" color="white"
                            >mdi-home-circle</v-icon
                          ><span class="white--text"
                            >หน้าแรก</span
                          ></v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        link
                        @click="$router.push({ path: '/game' })"
                      >
                        <v-list-item-title class="text-start align-center"
                          ><v-icon class="mr-3" color="white"
                            >mdi-play-circle</v-icon
                          ><span class="white--text"
                            >เล่นเกม</span
                          ></v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        link
                        @click="$router.push({ path: '/promotion' })"
                      >
                        <v-list-item-title class="text-start align-center"
                          ><v-icon class="mr-3" color="white"
                            >mdi-tag-outline</v-icon
                          ><span class="white--text"
                            >โปรโมชั่น</span
                          ></v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        link
                        @click="$router.push({ path: '/cashback' })"
                      >
                        <v-list-item-title class="text-start align-center"
                          ><v-icon class="mr-3" color="white">mdi-cash</v-icon
                          ><span class="white--text"
                            >รับเครดิตเงินคืน</span
                          ></v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        link
                        @click="$router.push({ path: '/invite' })"
                      >
                        <v-list-item-title class="text-start align-center"
                          ><v-icon class="mr-3" color="white"
                            >mdi-cash-multiple</v-icon
                          ><span class="white--text"
                            >หารายได้เสริม</span
                          ></v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        link
                        @click="$router.push({ path: '/ranking' })"
                      >
                        <v-list-item-title class="text-start align-center"
                          ><v-icon class="mr-3" color="white">mdi-trophy</v-icon
                          ><span class="white--text"
                            >รางวัลประจำเดือน</span
                          ></v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        link
                        @click="$router.push({ path: '/manual' })"
                      >
                        <v-list-item-title class="text-start align-center"
                          ><v-icon class="mr-3" color="white"
                            >mdi-notebook</v-icon
                          ><span class="white--text"
                            >คู่มือ</span
                          ></v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        link
                        @click="$router.push({ path: '/blog' })"
                      >
                        <v-list-item-title class="text-start align-center"
                          ><v-icon class="mr-3" color="white"
                            >mdi-text-box-multiple-outline</v-icon
                          ><span class="white--text"
                            >บทความ</span
                          ></v-list-item-title
                        >
                      </v-list-item>

                      <v-list-item :href="settingCheck" target="_blank">
                        <v-list-item-title class="text-start align-center"
                          ><v-icon class="mr-3" color="white"
                            >mdi-account-box</v-icon
                          ><span class="white--text"
                            >ติดต่อเรา</span
                          ></v-list-item-title
                        >
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </div>
                <div v-else>
                  <v-list nav dense>
                    <v-list-item-group
                      v-model="group"
                      active-class="white--text text--accent-4"
                    >
                      <v-list-item link @click="$router.push({ path: '/' })">
                        <v-list-item-title class="text-start align-center"
                          ><v-icon class="mr-3" color="white"
                            >mdi-home-circle</v-icon
                          ><span class="white--text"
                            >หน้าแรก</span
                          ></v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        link
                        @click="$router.push({ path: '/game' })"
                      >
                        <v-list-item-title class="text-start align-center"
                          ><v-icon class="mr-3" color="white"
                            >mdi-play-circle</v-icon
                          ><span class="white--text"
                            >เล่นเกม</span
                          ></v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        link
                        @click="$router.push({ path: '/promotion' })"
                      >
                        <v-list-item-title class="text-start align-center"
                          ><v-icon class="mr-3" color="white"
                            >mdi-tag-outline</v-icon
                          ><span class="white--text"
                            >โปรโมชั่น</span
                          ></v-list-item-title
                        >
                      </v-list-item>

                      <v-list-item
                        link
                        @click="$router.push({ path: '/ranking' })"
                      >
                        <v-list-item-title class="text-start align-center"
                          ><v-icon class="mr-3" color="white">mdi-trophy</v-icon
                          ><span class="white--text"
                            >รางวัลประจำเดือน</span
                          ></v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        link
                        @click="$router.push({ path: '/manual' })"
                      >
                        <v-list-item-title class="text-start align-center"
                          ><v-icon class="mr-3" color="white"
                            >mdi-notebook</v-icon
                          ><span class="white--text"
                            >คู่มือ</span
                          ></v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        link
                        @click="$router.push({ path: '/blog' })"
                      >
                        <v-list-item-title class="text-start align-center"
                          ><v-icon class="mr-3" color="white"
                            >mdi-text-box-multiple-outline</v-icon
                          ><span class="white--text"
                            >บทความ</span
                          ></v-list-item-title
                        >
                      </v-list-item>

                      <v-list-item :href="settingCheck" target="_blank">
                        <v-list-item-title class="text-start align-center"
                          ><v-icon class="mr-3" color="white"
                            >mdi-account-box</v-icon
                          ><span class="white--text"
                            >ติดต่อเรา</span
                          ></v-list-item-title
                        >
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </div>
              </v-navigation-drawer>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import ConfirmDialog from "./ConfirmDialog";
import RegisterDialog from "./RegisterDialog";
import ErrorDialog from "./ErrorDialog";
import { mapState } from "vuex";
export default {
  components: {
    ErrorDialog,
    RegisterDialog,
    ConfirmDialog,
  },
  computed: {
    settingCheck() {
      if (this.LineSetting) {
        let obj = this.LineSetting.find((val) => val.setting === "LINE");
        return obj ? obj.value : "";
      }
    },
    ...mapState({
      userBalance: (state) => state.user.userBalance,
      userProfile: (state) => state.user.userProfile,
      LineSetting: (state) => state.setting.LineSetting,
    }),
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  data: () => ({
    dataConfirmDialog: { title: "ต้องการออกจากระบบใช่หรือไม่?" },
    showDialogConfirm: false,
    showDialogRegister: false,
    showDialogErrorLogin: false,
    userTelNumber: null,
    passWord: null,
    dataError: {},
    drawer: false,
    group: null,
    curentPath: 0,
  }),
  methods: {
    onCancelConfirmDialog(onCancelConfirmDialog) {
      this.showDialogConfirm = onCancelConfirmDialog.isOpen;
      if (onCancelConfirmDialog.onAccept == true) {
        this.onLogOut();
      }
    },
    onLogOut() {
      localStorage.removeItem("toKen");
      this.$store.commit("user/clearUserDataWhenLogout");
      this.$router.push("/").catch(() => {});
    },
    onCancelErrorDialog(onCancelErrorDialog) {
      this.showDialogErrorLogin = onCancelErrorDialog;
    },
    onCancelDialogRegister(onCancelDialog) {
      this.showDialogRegister = onCancelDialog;
    },
    onCancelDialog() {
      this.$emit("onCancelDialog", false);
    },
    opentoast(massage) {
      this.$toast.error(massage, {
        position: "top-right",
      });
    },
    changePath(item) {
      this.curentPath = item;
    },
    async onLogin() {
      this.loading = true;

      let body = {
        username: this.userTelNumber,
        password: this.passWord,
      };

      let res = await this.$store.dispatch("user/userAuthen", body);

      if (res === true) {
        this.loading = false;
        this.getBalance();
      } else {
        this.showDialogErrorLogin = true;
        (this.dataError = {
          message: res,
        }),
          (this.loading = false);
      }
    },
    async getBalance() {
      if (localStorage.getItem("toKen") != null) {
        let res = await this.$store.dispatch("user/userGetBalance");
        if (res) {
          setTimeout(() => this.getBalance(), 3000);
        }
      }
    },
  },
  mounted() {
    if (localStorage.getItem("toKen")) {
      this.$store.commit("user/getCurentUser");
    }
    if (!window.getBalancing) {
      this.getBalance();
    }
    this.$nextTick(() => {
      this.waitRender = true;
    });
  },
};
</script>
<style scoped>
@media only screen and (max-width: 1440px) {
}
@media only screen and (max-width: 1536px) {
  .icon-money {
    font-size: 3.5em !important;
  }
  .text-credit-custom-1 {
    font-size: 15px !important;
  }
  .text-credit-custom-2 {
    font-size: 18px !important;
  }
}
@media only screen and (min-width: 1536px) and (max-width: 1736px) {
  .btn-purple {
    font-size: 18px !important;
  }
  .btn-orange {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 1160px) {
}
@media only screen and (max-width: 1025px) {
  .icon-money {
    font-size: 3.5em !important;
  }
  .text-credit-custom-1 {
    font-size: 15px !important;
  }
  .text-credit-custom-2 {
    font-size: 18px !important;
  }
}
@media only screen and (min-width: 961px) and (max-width: 1024px) {
}

@media only screen and (min-width: 769px) and (max-width: 960px) {
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
}
@media only screen and (max-width: 600px) {
}
@media only screen and (max-width: 599px) {
  .text-credit-custom-1 {
    font-size: 15px !important;
  }
  .text-credit-custom-2 {
    font-size: 18px !important;
  }
  .logout-custom {
    font-size: 12px !important;
  }
  .btn-purple {
    font-size: 14px !important;
  }
  .btn-orange {
    font-size: 14px !important;
  }
  .icon-money {
    font-size: 2em !important;
  }
}
@media only screen and (max-width: 320px) {
  .text-credit-custom-1 {
    font-size: 15px !important;
  }
  .text-credit-custom-2 {
    font-size: 18px !important;
  }
  .logout-custom {
    font-size: 12px !important;
  }
  .btn-purple {
    font-size: 14px !important;
  }
  .btn-orange {
    font-size: 14px !important;
  }
  .icon-money {
    font-size: 2em !important;
  }
}

.top-menu {
  text-decoration: none;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}
.border-bottom-custom {
  height: 4px;
}

.navbar-active .router-link-exact-active {
  border-bottom: 3px solid #f9690b;
}

.tab-custom {
  background: rgb(0, 0, 0, 0.5);
}
.form-icon-style {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: linear-gradient(
    90deg,
    rgba(255, 238, 38, 1) 0%,
    rgba(226, 53, 45, 1) 100%
  );
}

.test {
  border-radius: 4px;
  background: linear-gradient(180deg, #f5d413, #c23028);
  box-shadow: inset 0 0 10px #000000 !important ;
  border-top: 2px solid #f7ecff;
  border-right: 2px solid #f9750e;
  border-bottom: 2px solid #f7ecff;
  border-left: 2px solid #f9750e;
}
.icon-style {
  color: linear-gradient(
    180deg,
    rgba(196, 40, 150, 0.9) 0%,
    rgba(24, 0, 15, 1) 100%
  );
}
.logout-custom {
  background: linear-gradient(
    180deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(79, 12, 0, 1) 100%
  );
}

.icon-money {
  font-size: 4em;
  color: #531f6a;
  text-shadow: 0px 0px 1px #1a0216;
}
.text-credit-custom-1 {
  font-size: 20px;
  color: #1e0a25;
  font-weight: bold;
  text-shadow: 0px 0px 2px #1a0216;
}
.text-credit-custom-2 {
  font-size: 25px;
  color: white;
  font-weight: bold;
  text-shadow: 0px 0px 2px #1a0216;
}
</style>
