<template>
  <div class="">
    <v-row class="justify-center">
      <v-col
        v-for="(item, index) in allGame"
        :key="index"
        cols="11"
        sm="5"
        lg="5"
        md="5"
      >
        <div @click="currentClick = index" class="container-custom text-center">
          <v-img
            class="image"
            :src="`${configGlobal.prod.api}/${item.image}`"
          ></v-img>
          <div v-if="currentClick == index" class="middle">
            <button
              @click="
                onPlayGame(
                  item,
                  'play',
                  userToken != null ? userToken : null,
                  null
                )
              "
              v-if="userProfile.length != 0"
              class="btn-orange raise-play"
            >
              เล่นเลย
            </button>
            <button
              v-if="item.gameId != 'rps'"
              @click="onPlayGame(item, 'demo', null, null)"
              :loading="loadingGame"
              class="raise-test-play"
            >
              ทดลองเล่น
            </button>
          </div>
        </div>
      </v-col>
    </v-row>
    <GameSubDialog
      v-if="selectedSubgame"
      :dialog="showSubGameDialog"
      @onCancelDialog="onCancelDialog($event)"
      @onSelectGame="onSelectGame($event)"
    />
  </div>
</template>
<script>
import GameSubDialog from "../components/GameSubDialog";
import { mapState } from "vuex";
import config from "../config/config";
export default {
  components: {
    GameSubDialog,
  },
  mounted() {
    this.getAllGame();
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.user.userProfile,
      allGame: (state) => state.game.allGame,
      userToken: (state) => state.user.userToken,
    }),
    configGlobal() {
      return config;
    },
  },
  data: () => ({
    currentClick: null,
    selectedSubgame: false,
    currentGameSelected: {},
    loadingGame: false,
    showSubGameDialog: false,
    data_game: [
      {
        title: "game1",
        gameTag: "sa",
        img: require("../assets/sa.098389ff.png"),
      },
      {
        title: "game2",
        gameTag: "wm",
        img: require("../assets/wm.cec99433.png"),
      },
      {
        title: "game3",
        gameTag: "dg",
        img: require("../assets/dg.4290471b.png"),
      },
      {
        title: "game4",
        gameTag: "sexy",

        img: require("../assets/sexy.6941d11a.png"),
      },
      {
        title: "game5",
        gameTag: "amb",
        img: require("../assets/amb.6819f11b.png"),
      },
      {
        title: "game6",
        gameTag: "sp",

        img: require("../assets/sp.d56e503f.png"),
      },
      {
        title: "game7",
        gameTag: "pretty",
        img: require("../assets/pretty.9b07a22c.png"),
      },
      {
        title: "game8",
        gameTag: "pg2",
        subgame: true,
        img: require("../assets/pg.01cc9d85.png"),
      },
      {
        title: "game9",
        gameTag: "joker",
        subgame: true,

        img: require("../assets/joker.ae7b74d2.png"),
      },
      {
        title: "game10",
        gameTag: "hotg",
        img: require("../assets/hotgraph.ff1d4e47.png"),
      },
      {
        title: "game11",
        gameTag: "ka",
        subgame: true,
        img: require("../assets/ka.90dffedd.png"),
      },
    ],
  }),
  methods: {
    onSelectGame(game) {
      this.currentGameSelected.game.subGame = false; //เงื่อนไขเลือกเกมทำให้ false เพื่อข้าม
      this.onPlayGame(
        this.currentGameSelected.game,
        this.currentGameSelected.gameType,
        this.currentGameSelected.token,
        game.gameCode
      );
    },
    onCancelDialog(onCancelDialog) {
      this.showSubGameDialog = onCancelDialog;
      this.selectedSubgame = false;
      this.$store.commit("game/resetSubGameList");
    },

    async getAllGame() {
      let res = await this.$store.dispatch("game/getGame");
    },

    async onPlayGame(game, gameType, token, subGame) {
      this.currentGameSelected.game = JSON.parse(JSON.stringify(game));
      this.currentGameSelected.gameType = JSON.parse(JSON.stringify(gameType));
      this.currentGameSelected.token = JSON.parse(JSON.stringify(token));
      if (game.gameId == "rps") {
        this.$router.push({ path: "/minigame/rps" });
      } else {
        if (game.subGame == true || game.subGame == 1) {
          //เงื่อนไขเลือกเกม
          this.loadingGame = true;

          let res = await this.$store.dispatch(
            "game/getGameSubList",
            game.gameId
          );
          this.loadingGame = false;
          if (res === true) {
            this.showSubGameDialog = true;
            this.selectedSubgame = true;
          }
          return;
        }
        if (window.isGameWorking) {
          window.isGameWorking.close();
        }
        let routeData = this.$router.resolve({
          name: "PlayGame",
          params: {
            gameTag: game.gameId,
            gameType: gameType,
            subGame: subGame == null ? "noSubgame" : subGame,
            token: token == null ? "noToken" : token,
          },
          query: { openExternalBrowser: 1 },
        });

        window.open(routeData.href, "_blank");
      }
    },
  },
};
</script>
<style scoped>
.cursor-hover:hover {
  cursor: pointer;
  opacity: 0.7;
}

.container-custom:hover .image {
  opacity: 0.6;
}

.container-custom .middle {
  display: block;
}

.image {
  cursor: pointer;
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.middle {
  transition: 0.5s ease;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.container-custom {
  position: relative;
  width: 100%;
}

.text {
  background-color: #d1be11;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}
.raise-test-play:hover,
.raise-test-play:focus {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  transform: translateY(-0.25em);
}
.raise-test-play {
  --hover: #f9750e;
}

.raise-play:hover,
.raise-play:focus {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  transform: translateY(-0.25em);
}
.raise-play {
  --hover: #bb1abe;
}
.raise-play:hover,
.raise-play:focus {
  border-color: var(--hover);
  color: #45012f;
}

button:hover,
button:focus {
  border-color: var(--hover);
  color: #f9750e;
}
button {
  width: 150px !important;
  color: var(--color);
  transition: 0.45s;
  border-radius: 8px;
  background: #bb1abe;
  background-image: -webkit-linear-gradient(top, #bb1abe, #45012f);
  background-image: -moz-linear-gradient(top, #bb1abe, #45012f);
  background-image: -ms-linear-gradient(top, #bb1abe, #45012f);
  background-image: -o-linear-gradient(top, #bb1abe, #45012f);
  background-image: linear-gradient(to bottom, #bb1abe, #45012f);
  -webkit-border-radius: 5;
  -moz-border-radius: 5;
  border-radius: 5px;
  text-shadow: 0px 1px 3px #1a0216;
  -webkit-box-shadow: 0px 0px 8px #bc492c;
  -moz-box-shadow: 0px 0px 8px #bc492c;
  box-shadow: 0px 0px 8px #bc492c;
  color: #ffffff;
  font-size: 20px;
  padding: 4px 20px 4px 20px;
  border-top: solid #f7ecff 1px;
  border-right: solid #f9750e 1px;
  border-bottom: solid #f7ecff 1px;
  border-left: solid #f9750e 1px;
  text-decoration: none;
  border: 2px solid;
  font: inherit;
  line-height: 1;
  margin: 0.25em;
  padding: 1em 2em;
}

.btn-orange {
  width: 150px !important;
  color: var(--color);
  transition: 0.45s;
  border-radius: 8px;
  background: #bb1abe;
  background-image: -webkit-linear-gradient(top, #f3bb2f, #ee331d);
  background-image: -moz-linear-gradient(top, #f3bb2f, #ee331d);
  background-image: -ms-linear-gradient(top, #f3bb2f, #ee331d);
  background-image: -o-linear-gradient(top, #f3bb2f, #ee331d);
  background-image: linear-gradient(to bottom, #f3bb2f, #ee331d);
  -webkit-border-radius: 5;
  -moz-border-radius: 5;
  border-radius: 5px;
  text-shadow: 0px 1px 3px #1a0216;
  -webkit-box-shadow: 0px 0px 8px #bc492c;
  -moz-box-shadow: 0px 0px 8px #bc492c;
  box-shadow: 0px 0px 8px #bc492c;
  color: #ffffff;
  font-size: 20px;
  padding: 4px 20px 4px 20px;
  border-top: solid #f7ecff 1px;
  border-right: solid #f9750e 1px;
  border-bottom: solid #f7ecff 1px;
  border-left: solid #f9750e 1px;
  text-decoration: none;
  border: 2px solid;
  font: inherit;
  line-height: 1;
  margin: 0.25em;
  padding: 1em 2em;
}

@media only screen and (min-width: 960px) and (max-width: 1264px) {
  button {
    height: 40px !important ;
    line-height: 0 !important;
  }
  btn-orange {
    height: 40px !important ;
    line-height: 0 !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 960px) {
  button {
    height: 35px !important ;
    line-height: 0 !important;
    border: 1px solid !important;
  }
  btn-orange {
    height: 35px !important ;
    line-height: 0 !important;
    border: 1px solid !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 600px) {
  button {
    height: 35px !important ;
    line-height: 0 !important;
    border: 1px solid !important;
  }
  btn-orange {
    height: 35px !important ;
    line-height: 0 !important;
    border: 1px solid !important;
  }
}
</style>
