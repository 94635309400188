<template>
  <div>
    <div>
      <v-container style="height: 100%">
        <v-row>
          <ListMenu />
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import ConfirmDialog from "../components/ConfirmDialog";
import { mapState } from "vuex";
import Snow from "./Snow";
import Alert from "./Alert";
import ListMenu from "./ListMenu";
import RegisterDialog from "./RegisterDialog";
export default {
  computed: {
    ...mapState({
      userProfile: (state) => state.user.userProfile,
      userBalance: (state) => state.user.userBalance,
    }),
  },
  data: () => ({
    refId: null,
    dataConfirmDialog: { title: "ต้องการออกจากระบบใช่หรือไม่?" },
    showDialogConfirm: false,
    userTelNumber: null,
    passWord: null,
    showDialog: false,
    alert: {
      text: "",
      onAlert: false,
    },
    loading: false,
  }),
  components: {
    ConfirmDialog,
    Alert,
    Snow,
    ListMenu,
    RegisterDialog,
  },
  methods: {
    opentoast(massage) {
      this.$toast.error(massage, {
        position: "top-right",
      });
    },
    onCancelConfirmDialog(onCancelConfirmDialog) {
      this.showDialogConfirm = onCancelConfirmDialog.isOpen;
      if (onCancelConfirmDialog.onAccept == true) {
        this.onLogOut();
      }
    },

    onCancelDialog(onCancelDialog) {
      this.showDialog = onCancelDialog;
    },
    async onLogin() {
      this.loading = true;

      let body = {
        username: this.userTelNumber,
        password: this.passWord,
      };

      let res = await this.$store.dispatch("user/userAuthen", body);

      if (res === true) {
        this.loading = false;
        this.getBalance();
      } else {
        this.opentoast(res);
        // this.alert.onAlert = true;
        // this.alert.text = res;
        this.loading = false;
      }
    },
    onLogOut() {
      localStorage.removeItem("toKen");
      this.$store.commit("user/clearUserDataWhenLogout");
      this.$router.push("/").catch(() => {});
    },
    async getBalance() {
      if (localStorage.getItem("toKen") != null) {
        let res = await this.$store.dispatch("user/userGetBalance");
        if (res) {
          setTimeout(() => this.getBalance(), 3000);
        }
      }
    },
  },
  mounted() {
    if (localStorage.getItem("toKen")) {
      this.$store.commit("user/getCurentUser");
    }
    if (!window.getBalancing) {
      //this.getBalance();
    }
    this.$nextTick(() => {
      this.waitRender = true;
    });
  },
};
</script>

<style scoped>
.border-card {
  border-radius: 8px;
  opacity: 0.98;
  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(246, 197, 45, 1);
  background-image: linear-gradient(
    to bottom right,
    #321a04 0% 65%,
    #141414 95% 100%
  );
}
.border-card-detail {
  border-radius: 8px;
  opacity: 0.98;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(246, 197, 45, 1);
  background-color: #050505 !important;
}

@media only screen and (min-width: 1264px) and (max-width: 1372px) {
  .header {
    background: url("../assets/cover.png") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 847px !important;
  }
}
@media only screen and (min-width: 1373px) and (max-width: 1439px) {
  .header {
    background: url("../assets/BG-Home.png") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 1024px !important;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1535px) {
  .header {
    background: url("../assets/BG-Home.png") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 1200px !important;
  }
}
@media only screen and (min-width: 1536px) and (max-width: 1636px) {
  .header {
    background: url("../assets/cover.png") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 1016px !important;
  }
}
@media only screen and (min-width: 1637px) and (max-width: 1736px) {
  .header {
    background: url("../assets/cover.png") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 1078px !important;
  }
}
@media only screen and (max-width: 1263px) {
  .header {
    background: url("../assets/cover-mb.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 1700px !important;
    background-position-y: 450px !important;
  }
}

@media only screen and (max-width: 1160px) {
  .header {
    height: 1500px !important;
    background-position-y: 400px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .header {
    height: 1528px !important;
    background-position-y: 490px !important;
  }
}
@media only screen and (max-width: 961px) {
  .header {
    height: 1319px !important;
    background-position-y: 350px !important;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  /* .login-box {
    padding: 0px 0px 0px 0px !important;
    margin-top: -460px !important;
    width: 90% !important;
  } */
  /* .resize-logo-section {
    margin-top: -80px !important;
    padding-top: 0px !important;
  } */
  /* .resize-logo-img {
    padding-top: 0px;
    width: 72% !important;
  } */
  /* .text-slot9th {
    margin-top: -180px;
  } */
  .header {
    background: url("../assets/cover-mb.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 785px !important;
    background-position-y: 208px !important;
  }
  /* .form-text-header {
    font-size: 25px !important;
  } */
  /* .form-text-detail {
    font-size: 15px !important;
  } */
  /* .size-bullet {
    width: 25px !important;
  } */
}
@media only screen and (min-width: 769px) and (max-width: 960px) {
  /* .login-box {
    padding: 0px 0px 0px 0px !important;
    margin-top: -460px !important;
    width: 90% !important;
  }
  .resize-logo-section {
    margin-top: -80px !important;
    padding-top: 0px !important;
  } */
  /* .resize-logo-img {
     padding-top: 60px !important;
    width: 80% !important;
  } */
  .text-slot9th {
    margin-top: -180px;
  }
  .header {
    background: url("../assets/cover-mb.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 1180px !important;
    background-position-y: 400px !important;
  }
  .form-text-header {
    font-size: 25px !important;
  }
  .form-text-detail {
    font-size: 15px !important;
  }
  .size-bullet {
    width: 25px !important;
  }
}
@media only screen and (max-width: 600px) {
  .login-icon-size {
    font-size: 20px !important;
  }
  .btn-login-text {
    font-size: 0.9em !important;
    font-weight: 400 !important;
  }
  .login-box {
    padding: 0px 0px 0px 0px !important;
    /* margin-top: -385px !important; */
    width: 100% !important;
  }
  .resize-logo-section {
    /* margin-top: -70px !important; */
    padding-top: 0px !important;
  }
  .resize-logo-img {
    padding-top: 35px !important;
    width: 100% !important;
  }
  .text-slot9th {
    /* margin-top: -180px; */
  }
  .header {
    background: url("../assets/cover-mb.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 700px !important;
    background-position-y: 280px !important;
  }
  .form-text-header {
    font-size: 24px !important;
  }
  .form-text-detail {
    font-size: 11px !important;
  }
  .size-bullet {
    width: 20px !important;
  }
  .btn-line-regis {
    width: 120px !important;
  }
}
@media only screen and (max-width: 414px) {
  .form-text-header {
    font-size: 23px !important;
  }
  .form-text-detail {
    font-size: 13px !important;
  }
  .header {
    height: 535px !important;
    background-position-y: 174px !important;
  }
}
@media only screen and (max-width: 375px) {
  .form-text-header {
    font-size: 23px !important;
  }
  .form-text-detail {
    font-size: 13px !important;
  }
  .header {
    height: 535px !important;
    background-position-y: 174px !important;
  }
}
@media only screen and (max-width: 320px) {
  .form-text-header {
    font-size: 23px !important;
  }
  .form-text-detail {
    font-size: 13px !important;
  }
  .header {
    height: 448px !important;
    background-position-y: 260px !important;
  }
}
.header {
  /* display: flex;
  justify-content: flex-end; */
  width: 100%;
  height: 1420px !important;
  background: url("../assets/BG-Home.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.btn-login {
  border-radius: 8px;
  background: linear-gradient(
    0deg,
    rgba(128, 94, 23, 1) 0%,
    rgba(246, 197, 45, 1) 30%,
    70%,
    rgba(128, 94, 23, 1) 100%
  );
}
.btn-login-text {
  font-size: 1.5em;

  letter-spacing: 0.2px;
}
.form-icon-style {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(232, 232, 216, 1) 8%,
    rgba(236, 231, 217, 1) 11%,
    rgba(187, 179, 165, 1) 91%
  );
}
.resize-logo-img {
  width: 100%;
  height: 100%;
}
.resize-logo-section {
  padding-top: 40px;
  width: 100%;
  height: 100%;
}
/* .login-box {
  padding: 64px 40px 0px 0px;
} */
.form-text-header {
  font-size: 50px;
  color: #fff;
  font-weight: bold;
}
.form-text-detail {
  font-size: 28px;
  color: #fff;
}
.size-bullet {
  width: 40px;
}
.btn-line-regis {
  width: 300px;
}
.main-logo {
  animation: shake 8s;
  animation-iteration-count: infinite;
}
</style>
