<template>
  <div>
    <SectionOne />
    <SectionTwo />
    <SectionThree />
    <SectionFour />
  </div>
</template>
<script>
import { mapState } from "vuex";
import SectionGamePlay from "../../components/s_content_game";
import RegisterDialog from "../../components/RegisterDialog";
import SectionOne from "./section_one.vue";
import SectionTwo from "./section_two.vue";
import SectionThree from "./section_three.vue";
import SectionFour from "./section_four.vue";
export default {
  components: {
    SectionGamePlay,
    RegisterDialog,
    SectionOne,
    SectionTwo,
    SectionThree,
    SectionFour,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.user.userProfile,
    }),
  },
  methods: {
    onCancelDialog(onCancelDialog) {
      this.showDialog = onCancelDialog;
    },
  },
  data: () => ({
    showDialog: false,
    data_advantages: [
      {
        text: "ฝาก ถอน อัตโนมัติ 10 วินาที",
      },
      {
        text: "เล่นตรงกับผู้ให้บริการทั้งหมด ไม่ผ่านเอเย่นต์",
      },
      {
        text: "ฝาก ถอน ไม่จำกัดจำนวนครั้ง และจำนวนเงิน",
      },
      {
        text: "  แทงเดิมพันสูงสุด 500,000 บาท / ไม้",
      },
      {
        text: "  สมัครง่าย ไม่ต้องแอดไลน์ ไม่ต้องจำยูส",
      },
      {
        text: "แอดมินดูแลอย่างมืออาชีพ ตลอด 24 ชั่วโมง",
      },
      {
        text: "  โปรโมชั่นรายวัน โปรโมชั่นรายเดือน ดีสุดใน 3 โลก",
      },
    ],
    data_game: [
      {
        title: "game1",
        gameTag: "sa",
        img: require("../../assets/sa.098389ff.png"),
      },
      {
        title: "game2",
        gameTag: "wm",
        img: require("../../assets/wm.cec99433.png"),
      },
      {
        title: "game3",
        gameTag: "dg",
        img: require("../../assets/dg.4290471b.png"),
      },
      {
        title: "game4",
        gameTag: "sexy",

        img: require("../../assets/sexy.6941d11a.png"),
      },
      {
        title: "game5",
        gameTag: "amb",
        img: require("../../assets/amb.6819f11b.png"),
      },
      {
        title: "game6",
        gameTag: "sp",

        img: require("../../assets/sp.d56e503f.png"),
      },
      {
        title: "game7",
        gameTag: "pretty",
        img: require("../../assets/pretty.9b07a22c.png"),
      },
      {
        title: "game8",
        gameTag: "pg2",
        subgame: true,
        img: require("../../assets/pg.01cc9d85.png"),
      },
      {
        title: "game9",
        gameTag: "joker",
        subgame: true,

        img: require("../../assets/joker.ae7b74d2.png"),
      },
      {
        title: "game10",
        gameTag: "hotg",
        img: require("../../assets/hotgraph.ff1d4e47.png"),
      },
      {
        title: "game11",
        gameTag: "ka",
        subgame: true,
        img: require("../../assets/ka.90dffedd.png"),
      },
    ],
  }),
};
</script>
<style scoped lang="scss">
@media only screen and (max-width: 1440px) {
  .form-text-header {
    font-size: 40px !important;
  }
  .form-text-detail {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 1536px) {
  .form-text-header {
    font-size: 40px !important;
  }
  .form-text-detail {
    font-size: 18px !important;
  }
}
@media only screen and (min-width: 1536px) and (max-width: 1736px) {
  .form-text-header {
    font-size: 40px !important;
  }
  .form-text-detail {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 1160px) {
}
@media only screen and (max-width: 1024px) {
}
@media only screen and (max-width: 961px) {
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
}
@media only screen and (min-width: 769px) and (max-width: 960px) {
  .text-slot9th {
    margin-top: -180px;
  }

  .form-text-header {
    font-size: 25px !important;
  }
  .form-text-detail {
    font-size: 15px !important;
  }
  .size-bullet {
    width: 25px !important;
  }
}
@media only screen and (max-width: 600px) {
  .login-icon-size {
    font-size: 20px !important;
  }
  .btn-login-text {
    font-size: 0.9em !important;
    font-weight: 400 !important;
  }
  .login-box {
    padding: 0px 0px 0px 0px !important;
    /* margin-top: -385px !important; */
    width: 100% !important;
  }
  .resize-logo-section {
    /* margin-top: -70px !important; */
    padding-top: 0px !important;
  }
  .resize-logo-img {
    padding-top: 60px !important;
    width: 100% !important;
  }
  .text-slot9th {
  }

  .form-text-header {
    font-size: 24px !important;
  }
  .form-text-detail {
    font-size: 15px !important;
  }
  .size-bullet {
    width: 20px !important;
  }
  .btn-line-regis {
    width: 120px !important;
  }
}
@media only screen and (max-width: 375px) {
  .header {
    height: 635px !important;
    background-position-y: 255px !important;
  }
}

.btn-login {
  border-radius: 8px;
  background: linear-gradient(
    0deg,
    rgba(128, 94, 23, 1) 0%,
    rgba(246, 197, 45, 1) 30%,
    70%,
    rgba(128, 94, 23, 1) 100%
  );
}
.btn-login-text {
  font-size: 1.5em;

  letter-spacing: 0.2px;
}
.form-icon-style {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(232, 232, 216, 1) 8%,
    rgba(236, 231, 217, 1) 11%,
    rgba(187, 179, 165, 1) 91%
  );
}
.resize-logo-img {
  padding-top: 40px;
  width: 100%;
  height: 100%;
}
.resize-logo-section {
  padding-top: 40px;
  width: 100%;
  height: 100%;
}

.form-text-header {
  font-size: 50px;
  color: #fff;
  font-weight: bold;
}
.form-text-detail {
  font-size: 28px;
  color: #fff;
}
.size-bullet {
  width: 40px;
}
.btn-line-regis {
  width: 300px;
}
.cursor-hover:hover {
  cursor: pointer;
  opacity: 0.7;
}
.img-hover-zoom {
  height: 300px; /* [1.1] Set it as per your need */
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition: transform 0.5s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  transform: scale(1.5);
}

.center {
  position: absolute;
  top: 23%;
  width: 100%;
  text-align: center;
}
</style>