import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/Home.vue";
import Ranking from "../views/ranking/Ranking";
import Game from "../views/game/Game.vue";
import Invite from "../views/invite/Invite.vue";
import Promotion from "../views/promotion/Promotion";
import Register from "../views/register/Register";
import Transaction from "../views/transaction/Transaction";
import PlayGame from "../views/play/PlayGame";
import CashBack from "../views/cashback/CashBack";
import Blog from "../views/blog/Blog";
import RegisterNoRef from "../views/register/RegisterNoRef";
import config from "../config/config";
import store from "../store/index";
import Manual from "../views/manual/Manual";
import ManualDetail from "../views/manual/ManualDetail";

import rpsGame from "../views/minigame/rpsGame";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      guest: true,
    },
  },
  { 
    path: "*",
    name: "error",
    component: Home,
    meta: { guest: true },
  },

  {
    path: "/transaction",
    name: "Transaction",
    component: Transaction,
    meta: { requiresAuth: true },
  },
  {
    path: "/ranking",
    name: "Ranking",
    component: Ranking,
    meta: { guest: true },
  },
  {
    path: "/game",
    name: "Game",
    component: Game,
    meta: { guest: true },
  },
  {
    path: "/promotion",
    name: "Promotion",
    component: Promotion,
    meta: { guest: true },
  },
  {
    path: "/register/:refId",
    name: "Register",
    component: Register,
    meta: { guest: true },
  },
  {
    path: "/register",
    name: "RegisterNoRef",
    component: RegisterNoRef,
    meta: { guest: true },
  },
  {
    path: "/play/:gameTag/:gameType/:subGame/:token",
    name: "PlayGame",
    component: PlayGame,
    meta: { guest: true },
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
    meta: { guest: true },
  },
  {
    path: "/invite",
    name: "Invite",
    component: Invite,

    meta: { requiresAuth: true },
  },
  {
    path: "/contact",
  },
  {
    path: "/cashback",
    name: "CashBack",
    component: CashBack,
    meta: { requiresAuth: true },
  },
  {
    path: "/manual",
    name: "Manual",
    component: Manual,
    meta: { guest: true },
  },

  {
    path: "/manual/:title",
    name: "ManualDetail",
    component: ManualDetail,
    meta: { guest: true },
  },
  // {
  //   path: "/minigame",
  //   name: "MinigameAll",
  //   component: MinigameAll,
  //   meta: { guest: true },
  // },
  {
    path: "/minigame/rps",
    name: "rpsGame",
    component: rpsGame,
    meta: { guest: true },
  },
];
const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("toKen") != null) {
      next();
      return;
    }
    next("/register");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem("toKen") != null) {
      next();

      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
