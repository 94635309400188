export default {
  devMode: true,
  baseURL: "https://nh1168.com",
  lineURL: "http://line.me/ti/p/~@nh1168",
  gaCode: "G-WCY20HWQXH",
  api: {
    user: `user`,
    game: `game`,
    withdraw: `withdraw`,
    ranking: `ranking`,
    transfer: `transfer`,
    deposit: `deposit`,
    promotion: `promotion`,
    invite: `invite`,
    referer: `referer`,
    setting: `setting`,
    annouce: `announce`,
    minigame: `minigame`,
  },
  dev: {
    api: "https://api.nh1168.com",
    //api: "https://test-web-api.flexbox.gg",
    //api: "https://api.fx77.co",
    api_invite: "https://nh1168.com",
  },
  prod: {
    api: "https://api.nh1168.com",
    //api: "https://api.fx77.co",
    api_invite: "https://nh1168.com",
  },
};
