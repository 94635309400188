import { globalAxios } from "../../../axios-config";
import config from "../../config/config";
const state = () => ({
  newsList: [],
});

const mutations = {
  setNewsList(state, payload) {
    state.newsList = payload;
  },
  updateNewsList(state, payload) {
    let anouncList = localStorage.getItem("newsList");
    if (!anouncList) {
      let data = [];
      data.push(payload.id);
      localStorage.setItem("newsList", JSON.stringify(data));
    } else {
      let newData = anouncList;
      newData = JSON.parse(newData);
      newData.push(payload.id);
      localStorage.setItem("newsList", JSON.stringify(newData));
    }
  },
};

const actions = {
  async getAnnounce({ commit }) {
    try {
      let response = await globalAxios({
        method: "get",
        url: `/${config.api.annouce}`,
      });
    
      if (response != null) {
        commit("setNewsList", response.data.datas);
      }
      return true;
    } catch (error) {
      
      return error.response.data.errors;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
