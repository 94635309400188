<template>
  <div class="px-4" style="padding-bottom: 140px; padding-top: 30px">
    <v-container class="pb-16 px-lx-16 px-lg-16 section-content-custom">
      <v-row style="margin-top: -43px">
        <v-col
          cols="8"
          lg="4"
          md="4"
          sm="6"
          xl="4"
          class="title-custom text-center"
          >โปรโมชั่น</v-col
        >
      </v-row>
      <v-row class="pt-10 justify-center">
        <v-col
          v-for="(item, index) in dataPromotion"
          :key="index"
          cols="11"
          sm="10"
          md="10"
          xl="9"
          lg="9"
          class="ma-2 border-card"
        >
          <v-row>
            <v-col
              @click="onShowPromotion(index)"
              cols="12"
              lg="12"
              xl="12"
              md="12"
              sm="12"
              class="pa-2 cursor-hover"
            >
              <v-img
                :lazy-src="item.img"
                cover
                class="img-promotion"
                :src="item.img"
              ></v-img>
            </v-col>
            <v-col
              class="d-flex flex-column align-start justify-center"
              cols="12"
              lg="12"
              xl="12"
              md="12"
              sm="12"
              v-if="promotionIndex == index"
            >
              <div style="color: gold">{{ item.title }}</div>
              <ul class="pb-4">
                <li
                  v-for="(item2, index) in item.detail"
                  :key="index"
                  class="text-promotion-detail text-start"
                >
                  {{ item2.text }}
                </li>
              </ul>
              <v-btn
                v-if="userProfile.length == 0"
                width="100%"
                class="btn-orange"
                depressed
              >
                <div style="color: white" class="btn-login-text pl-3">
                  กรุณาเข้าสู่ระบบ
                </div>
              </v-btn>
              <v-btn
                @click="onGetPromotion(item, index)"
                v-else
                width="100%"
                class="btn-orange"
                depressed
              >
                <div style="color: white" class="btn-login-text pl-3">
                  รับโปรโมชั่น
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
import config from "../../config/config";
export default {
  methods: {
    onShowPromotion(index) {
      this.promotionIndex = index;
    },
    opentoast(type, massage) {
      this.$toast[type](massage, {
        position: "top-right",
      });
    },

    async onGetPromotion(item, index) {
      if (index == 3) {
        //รับเครดิตเงินคืน 0.3%
        this.$router.push({ path: "/cashback" });
        return 1;
      } else if (index == 4) {
        this.$router.push({ path: "/invite" }); //ชวนรเพื่อน
        return 1;
      } else if (index == 5 || index == 6) {
        window.open(config.prod.api_invite, "_blank"); //เล่นเสียโปรวันเกิด
        return 1;
      }
      let body = {
        bonusId: item.pm_type,
      };
      this.isLoadding = true;
      let res = await this.$store.dispatch("promotion/getPromotion", body);
      this.isLoadding = false;

      if (res.msg) {
        this.opentoast("success", res.response.message);
      } else {
        this.opentoast("error", res.response.message);
      }
    },
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.user.userProfile,
    }),
  },
  data: () => ({
    promotionIndex: 0,
    txtMessage: null,
    isLoadding: false,
    dataPromotion: [
      // {
      //     pm_type: 4,
      //   img: require("../../assets/casino-software_1040x1040.jpg"),
      //   title: "สมาชิกใหม่ รับฟรี 3 เท่า!",
      //   detail: [
      //     {
      //       text: "ฝากเงินคร้ังแรก 50 หรือ 100 เท่านั้น",
      //     },
      //     {
      //       text: "ไม่ต้องทำเทิร์น แต่ต้องทำยอดให้ครบถึงจะถอนได้",
      //     },
      //     {
      //       text: "ทำยอด 750 / 1,500 ถอนได้เงินจริง 150 / 300 บาท",
      //     },
      //     {
      //       text: "ทำยอด 750 / 1,500 ถอนได้เงินจริง 150 / 300 บาท",
      //     },
      //   ],
      // },
      // {
      //    pm_type: 1,
      //   img: require("../../assets/casino-software_1040x1040.jpg"),
      //   title: "สมาชิกใหม่ ฟรีโบนัส 60%",
      //   detail: [
      //     {
      //       text: "ฝากเงินคร้ังแรกขั้นต่ำ 100 บาท",
      //     },
      //     {
      //       text: "โบนัสสูงสุดไม่เกิน 500 บาท",
      //     },
      //     {
      //       text: "ทำเทิร์น 20 เท่า จ่ายสูงสุด 4,000 บาท",
      //     },
      //     {
      //       text: "หากพบการกระทำส่อทุจริต ปั๊มเทิร์น ใช้โปรแกรมช่วยเล่น หรือพบการทำเป็นขบวนการ ทีมงานจะระงับบัญชีทันที",
      //     },
      //   ],
      // },
      {
        pm_type: 2,
        img: require("../../assets/Promotion-01.png"),
        title: "ฝากครั้งแรกของวัน ฟรี! 10%",
        detail: [
          {
            text: "รับได้ 1 ครั้งต่อวัน ไม่มีขั้นต่ำ",
          },
          {
            text: "โบนัสสูงสุดไม่เกิน 500 บาท",
          },
          {
            text: "ทำเทิร์น 20 เท่า จ่ายสูงสุด 40,000 บาท",
          },
          {
            text: "หากพบการกระทำส่อทุจริต ปั๊มเทิร์น ใช้โปรแกรมช่วยเล่น หรือพบการทำเป็นขบวนการ ทีมงานจะระงับบัญชีทันที",
          },
        ],
      },
      {
        pm_type: 0,
        img: require("../../assets/Promotion-02.png"),
        title: "รับเครดิตเงินคืน 0.3%",
        detail: [
          {
            text: "เช่น มียอดเล่น 100,000 บาท ได้ทันที 300 บาท",
          },
          {
            text: "ถอนเป็นเงินสดได้ทันที ไม่ต้องทำเทิร์น",
          },
          {
            text: "นับยอดเล่นจากทั้งสล็อตและคาสิโน",
          },
          {
            text: "หากพบการกระทำส่อทุจริต ปั๊มเทิร์น ใช้โปรแกรมช่วยเล่น หรือพบการทำเป็นขบวนการ ทีมงานจะระงับบัญชีทันที",
          },
        ],
      },
      {
        pm_type: 0,
        img: require("../../assets/Promotion-03.png"),
        title: "ชวนเพื่อน รับ 0.6% - 0.9% ทุกยอดเล่น",
        detail: [
          {
            text: "หารายได้เสริม รับโบนัสได้ตลอดชีพ",
          },
          {
            text: "ถอนเป็นเงินสดได้ทันที ไม่ต้องทำเทิร์น",
          },
          {
            text: "ได้โบนัสสูงสุดถึง 10 ขั้นสมาชิก",
          },
          {
            text: "หากพบการกระทำส่อทุจริต ปั๊มเทิร์น ใช้โปรแกรมช่วยเล่น หรือพบการทำเป็นขบวนการ ทีมงานจะระงับบัญชีทันที",
          },
        ],
      },
      {
        pm_type: 0,
        img: require("../../assets/Promotion-04.png"),
        title: "แทงถูกหรือแทงผิด 10 ตาติด",
        detail: [
          {
            text: "แทงถูกหรือแทงผิด 10 ตาติด เฉพาะคาสิโน",
          },
          {
            text: "จะได้โบนัสตามไม้ที่แทงน้อยที่สุด สูงสุด 5,000 บาท",
          },
          {
            text: "ถอนเป็นเงินสดได้ทันที ไม่ต้องทำเทิร์น",
          },
          {
            text: "หากตรวจพบว่ามีการแทงสวนกันจะถูกตัดสิทธิ์ทันที",
          },
        ],
      },
      {
        pm_type: 0,
        img: require("../../assets/Promotion-05.png"),
        title: "วันเกิด รับเครดิตฟรี 500 บาท ไม่ต้องทำเทิร์น",
        detail: [
          {
            text: "มียอดเล่นสม่ำเสมออย่างน้อย1เดือน",
          },
           {
            text: "ฝากขั้นต่ำ 2,000 บาท",
          },
           {
            text: "เทิร์นรวม 15,000 บาท ขึ้นไป",
          },
         
        ],
      },
    ],
  }),
};
</script>
<style scoped>
.btn-login-text {
  font-size: 1.2em;

  letter-spacing: 0.2px;
}
.btn-signin {
  border-radius: 8px;
  background: linear-gradient(
    0deg,
    rgba(128, 94, 23, 1) 0%,
    rgba(246, 197, 45, 1) 30%,
    70%,
    rgba(128, 94, 23, 1) 100%
  );
}
.divider-custom {
  background: linear-gradient(
    90deg,
    rgba(255, 235, 0, 1) 0%,
    rgba(121, 9, 9, 1) 50%,
    rgba(255, 235, 0, 1) 100%
  );
}
.text-title {
  font-size: 20px;
  background: #cf0000;
  background: -webkit-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: -moz-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.border-card {
  border-radius: 8px;
  opacity: 0.98;
  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(205, 62, 19, 1);
  background-color: rgba(51, 6, 59, 0.75) !important;
}
.img-promotion {
  height: 100%;
  width: 100%;
}

.text-promotion-detail {
  font-size: 1rem;
  font-weight: 400;
}

.title-custom {
  top: -1.4% !important;
}
.cursor-hover:hover {
  cursor: pointer;
  opacity: 0.9;
}
</style>