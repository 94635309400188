<template>
  <div style="background-color: #3e133a">
    <v-row
      ><v-col>
        <v-container>
          <div
            class="text-start text-promotion-header"
            style="text-shadow: 0px 1px 3px #1a0216"
          >
            บัญชีธนาคารสำหรับฝากเงิน
          </div>
          <div style="color: white" class="text-start">
            ฝากเงินด้วยระบบอัตโนมัติ ขั้นต่ำ 1 บาท
            คัดลอกเลขบัญชีแล้วทำการโอนเงิน ยอดเงินจะเข้าสู่ระบบไม่เกิน 30 วินาที
            <div class="border-card-bank1 mt-3">
              <v-row class="px-3 py-2">
                <v-col
                  v-for="(bank, index) in depositData"
                  :key="index"
                  class="pb-0"
                >
                  <v-row class="justify-space-between align-center">
                    <div
                      style="
                        color: white;
                        font-size: 18px;
                        text-shadow: 0px 1px 3px #1a0216;
                      "
                    >
                      โอนเงินมาที่บัญชี
                    </div>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="3" xl="3" lg="3" md="3" sm="3">
                      <v-img :src="imageBank(bank.bankType)"></v-img>
                    </v-col>

                    <v-col
                      class="d-flex flex-column justify-center"
                      cols="9"
                      xl="5"
                      lg="5"
                      md="5"
                      sm="5"
                    >
                      <div
                        style="color: white; text-shadow: 0px 1px 3px #1a0216"
                      >
                        {{ bank.bankType }}
                      </div>
                      <div
                        style="color: #f6b235; text-shadow: 0px 1px 3px #1a0216"
                      >
                        {{ chekStringBank(bank.bankType, bank.bankAcc) }}
                      </div>
                      <div
                        style="color: #f6b235; text-shadow: 0px 1px 3px #1a0216"
                      >
                        {{ bank.bankName }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider
                    class="mt-6 mb-3"
                    style="background-color: #ae258a"
                  ></v-divider>
                  <v-btn
                    width="100%"
                    class="btn-orange"
                    depressed
                    @click="doCopy()"
                  >
                    <div style="color: white" class="btn-login-text pl-3">
                      <v-icon small class="pr-1" style="color: white"
                        >mdi-content-copy</v-icon
                      >
                      คัดลอกเลขบัญชี
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div class="text-center pt-3">
              ใช้บัญชีด้านล่างสำหรับการฝากเงินเท่านั้น
            </div>
            <div class="border-card-bank2 mt-3">
              <div class="border-card-bank-user">
                <v-row>
                  <v-col
                    v-if="$vuetify.breakpoint.name != 'xs'"
                    cols="3"
                    xl="2"
                    lg="2"
                    md="2"
                    sm="2"
                    class="d-flex flex-column align-center"
                  >
                    <v-img
                      width="100%"
                      :src="imageBank(userProfile.bankType)"
                    ></v-img>
                  </v-col>
                  <v-col
                    class="
                      d-flex
                      flex-column
                      justify-center
                      justify-sm-start
                      justify-md-start
                      justify-lg-start
                      justify-xl-start
                    "
                    cols="7"
                    xl="5"
                    lg="5"
                    md="5"
                    sm="5"
                  >
                    <div>
                      {{ userProfile.firstname }}
                      {{ userProfile.lastname }}
                    </div>
                    <div style="color: grey">
                      {{ userProfile.bankType }}
                    </div>
                  </v-col>
                  <v-col
                    cols="5"
                    xl="5"
                    lg="5"
                    md="5"
                    sm="5"
                    class="
                      px-sm-3 px-0
                      d-flex
                      flex-column
                      align-lg-end
                      align-xl-end
                      align-md-end
                      align-sm-end
                      align-center
                    "
                  >
                    <div>
                      {{
                        chekStringBank(
                          userProfile.bankType,
                          userProfile.bankAcc
                        )
                      }}
                    </div>
                  </v-col>
                </v-row>
              </div>
              <h4
                class="text-center pt-3"
                style="text-shadow: 0px 1px 3px #1a0216"
              >
                ฝากเงินขั้นต่ำ 1 บาท
              </h4>
              <h5
                class="text-center pt-3"
                style="text-shadow: 0px 1px 3px #1a0216"
              >
                ชื่อบัญชีฝากต้องตรงกับบัญชีถอน ไม่เช่นนั้นจะถอนเงินไม่ได้
              </h5>
              <h5
                class="text-center pt-3"
                style="text-shadow: 0px 1px 3px #1a0216"
              >
                (ระบบจะจับคู่ ยอดเงินเครดิตเข้าบัญชีท่านอัตโนมัติ)
              </h5>
              <div class="d-flex justify-center pt-3">
                <v-icon class="pr-1" style="color: green"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                <h4 style="color: greentext-shadow: 0px 1px 3px #1a0216">
                  ฟรีค่าธรรมเนียมการโอนเงินทุกธนาคาร
                </h4>
              </div>
              <v-divider
                class="mt-6 mb-3"
                style="background-color: #ffc107"
              ></v-divider>
              <div>
                <div class="d-flex pt-3">
                  <v-icon class="pr-1" style="color: white"
                    >mdi-format-list-bulleted-square</v-icon
                  >
                  <h4 style="color: white">โปรดอ่านก่อน</h4>
                </div>
                <ul
                  class="pt-1"
                  v-for="(item, index) in conditionDepositData"
                  :key="index"
                >
                  <li style="font-size: 15px; text-shadow: 0px 1px 3px #1a0216">
                    {{ item.text }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  setup() {},
  data: () => ({
    doCopy() {
      try {
        navigator.clipboard.writeText(this.depositData[0].bankAcc);
        this.opentoast("success", "คัดลอกเลขบัญชีเรียบร้อย");
        this.copyClip = true;
      } catch (err) {
        this.opentoast("error", "เกิดข้อผิดพลาดในการคัดลอกเลขบัญชี");
      }
    },
    conditionDepositData: [
      {
        text: "หากฝากเงินแล้วยอดเงินไม่เข้าหลังโอนเงิน 3-5นาที โปรดแจ้งแอดมิน",
      },
      {
        text: "โปรดตรวจสอบบัญชีทุกครั้งก่อนทำการโอนเงิน",
      },
      {
        text: "ในช่วงเวลา 01.00 - 05.00 ระบบอาจล่าช้าหากมีปัญหากรุณาติดต่อแอดมิน",
      },
    ],
  }),
  methods: {
    opentoast(type, massage) {
      this.$toast[type](massage, {
        position: "top-right",
      });
    },
    chekStringBank(BankType, BankAcc) {
      let banktype = 0;
      if (
        BankType == "WALLET" ||
        BankType == "KBANK" ||
        BankType == "SCB" ||
        BankType == "BBL" ||
        BankType == "KTB" ||
        BankType == "BAY" ||
        BankType == "IBANK" ||
        BankType == "GSB" ||
        BankType == "TTB" ||
        BankType == "TISCO" ||
        BankType == "GHB" ||
        BankType == "LHBANK" ||
        BankType == "CIMB" ||
        BankType == "UOB"
      ) {
        banktype = 1;
      } else if (BankType == "BAAC") {
        banktype = 2;
      } else {
        banktype = 3;
      }
      switch (banktype) {
        case 1:
          return [
            BankAcc.slice(0, 3),
            "-",
            BankAcc.slice(3, 4),
            "-",
            BankAcc.slice(4, 9),
            "-",
            BankAcc.slice(9, 10),
          ].join("");
        case 2:
          return BankAcc;
        case 3:
          return [
            BankAcc.slice(0, 4),
            "-",
            BankAcc.slice(4, 7),
            "-",
            BankAcc.slice(7, 11),
            "-",
            BankAcc.slice(11, 14),
          ].join("");
      }
    },
    imageBank(bankname) {
      switch (bankname) {
        case "WALLET":
          return require("../assets/truemoneywallet.webp");
        case "KBANK":
          return require("../assets/kbank.png");
        case "SCB":
          return require("../assets/scb.png");
        case "BBL":
          return require("../assets/bbl.png");
        case "KTB":
          return require("../assets/ktb.png");
        case "BAY":
          return require("../assets/bay.png");
        case "IBANK":
          return require("../assets/ibank.png");
        case "GSB":
          return require("../assets/gsb.png");
        case "TTB":
          return require("../assets/tmb.png");
        case "TISCO":
          return require("../assets/tisco.png");
        case "KK":
          return require("../assets/kk.png");
        case "GHB":
          return require("../assets/ghb.jpg");
        case "LHBANK":
          return require("../assets/lh.png");
        case "BAAC":
          return require("../assets/baac.png");
        case "CIMB":
          return require("../assets/cimb.png");
        case "UOB":
          return require("../assets/uob.png");
      }
    },
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.user.userProfile,
      depositData: (state) => state.transection.deposit,
    }),
  },
};
</script>
<style scoped>
.text-promotion-header {
  font-size: 22px;
  color: #ffc107;
}

.border-card-bank1 {
  border-radius: 8px !important;
  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(150, 16, 151, 0.8);
  background: url("../assets/BG-SC1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 10px #ee331d !important ;
}
.border-card-bank2 {
  border-radius: 8px !important;
  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(150, 16, 151, 0.8);
  background: url("../assets/BG-SC1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 10px #ee331d !important ;
}
.border-card-bank-user {
  box-shadow: 0px 1rem 1.5rem rgba(0, 0, 0, 0.3);
  border-color: #ffc107;
  opacity: 0.98;
  padding: 1rem;
  color: white !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  background-image: linear-gradient(
    to bottom right,
    #000000 0% 65%,
    #141414 95% 100%
  );
}
.btn-signin {
  border-radius: 8px;
  background: linear-gradient(90deg, #805e17 0%, #f6c52d 100%);
}
</style>