import config from "../../config/config";
import { globalAxios } from "../../../axios-config";
const state = () => ({
  historywithdraw: [],
  historydeposit: [],
  deposit: [],
});

const mutations = {
  setDataHisoryWithdraw(state, payload) {
    state.historywithdraw = payload;
  },
  setDepositData(state, payload) {
    state.deposit = payload;
  },
  setHisoryDepositData(state, payload) {
    state.historydeposit = payload;
  },
};

const actions = {
  async userGetHisoryWithdraw({ commit }) {
    try {
      let response = await globalAxios({
        method: "get",
        url: `/${config.api.withdraw} `,
      });
      // console.log('ssssssssssssssssss',response.data.datas)
      commit("setDataHisoryWithdraw", response.data.datas);
      return response.data;
    } catch (error) {
      return error.response.data.errors;
    }
  },
  async getDeposit({ commit }) {
    try {
      let response = await globalAxios({
        method: "get",
        url: `/${config.api.deposit} `,
      });

      commit("setHisoryDepositData", response.data.datas);
      commit("setDepositData", response.data.banks);
      return response.data;
    } catch (error) {
      return error.response.data.errors;
    }
  },
  async withDraw({ commit }, body) {
    try {
      let response = await globalAxios({
        method: "post",
        url: `/${config.api.withdraw} `,
        data: body,
      });
      return { msg: true, data: response };
    } catch (error) {
      //console.log("xxxx");
      return { msg: false, data: error.response.data };
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
