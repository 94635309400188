<template>
  <v-container
    :class="$vuetify.breakpoint.xl || $vuetify.breakpoint.lg ? 'py-0' : 'py-8'"
  >
    <v-row class="justify-center"
      ><v-col cols="12" class="d-flex flex-column align-center shadow">
        <v-img
          class="mb-0"
          :width="
            $vuetify.breakpoint.xl || $vuetify.breakpoint.lg ? '75%' : '96%'
          "
          contain
          src="../../assets/Main-Banner.png"
        >
        </v-img>
      </v-col>
      <v-col class="d-flex flex-column" cols="12" lg="9" xl="9" md="10" sm="11">
        <div class="form-text-detail text-center">
          ศูนย์รวมพนัน กีฬา คาสิโน & สล็อต ออนไลน์ บริษัทหลักอยู่ประเทศมาเก๊า
          รวมกลุ่มก่อตั้งโดยนายทุนคนไทย และ คนจีน
          ผู้มีประสบการณ์ทางด้านนี้โดยตรง ไร้กังวลเรื่องการถูกหลอก
          เล่นตรงกับผู้ให้บริการทั้งหมด ไม่ผ่านเอเย่นต์ ปลอดภัย มั่นใจได้
          ไม่ว่าจะยอดเล็ก หรือ ยอดใหญ่ เราจ่ายให้ทันที ระบบ ฝาก - ถอน AUTO
          เพื่อให้ผู้เดิมพันสะดวกสบายขึ้น
          ระบบแนะนำเพื่อนสร้างรายได้ให้คุณมากกว่า 100,000 บาท
          พร้อมโปรโมชั่นเด็ดๆ เพียงล็อคอินเข้ามาเดิมพันกับเราทุกวัน
          ลุ้นรับเครดิตฟรีประจำวันได้เลย
          <!-- {{
            $vuetify.breakpoint.xs
              ? ""
              : "เสือมังกร รูเล็ตกำกั่ว และเกมสล็อตออนไลน์ เยอะและมากที่สุด"
          }} -->
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="3" xl="2" lg="2" sm="4">
        <a style="text-decoration: none" :href="settingCheck" target="_blank">
          <v-btn
            :height="$vuetify.breakpoint.xl ? '80px' : '48px'"
            class="btn-line"
            block
            depressed
          >
            <img
              class="invert"
              img
              src="../../assets/line.png"
              :width="$vuetify.breakpoint.xl ? '40px' : '20px'"
            />
            <div class="btn-login-text pl-2">
              <v-icon class="pa-0" color="white">mdi-at</v-icon>
              nh1168
            </div>
          </v-btn>
        </a>
      </v-col>
      <v-col
        v-if="userProfile.length == 0"
        cols="12"
        md="3"
        xl="2"
        lg="2"
        sm="4"
      >
        <v-btn
          :height="$vuetify.breakpoint.xl ? '80px' : '48px'"
          class="btn-register"
          block
          depressed
          @click.stop="showDialog = true"
        >
          <img
            class="invert"
            img
            src="../../assets/add-user.png"
            :width="$vuetify.breakpoint.xl ? '40px' : '20px'"
          />
          <div class="btn-login-text pl-2">สมัครสมาชิก</div>
        </v-btn>
        <RegisterDialog
          :dialog="showDialog"
          @onCancelDialog="onCancelDialog($event)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import RegisterDialog from "../../components/RegisterDialog.vue";
import { mapState } from "vuex";
export default {
  data: () => ({
    showDialog: false,
  }),
  computed: {
    settingCheck() {
      if (this.LineSetting) {
        let obj = this.LineSetting.find((val) => val.setting === "LINE");
        return obj ? obj.value : "";
      }
    },
    ...mapState({
      userProfile: (state) => state.user.userProfile,
      LineSetting: (state) => state.setting.LineSetting,
    }),
  },
  methods: {
    onCancelDialog(onCancelDialog) {
      this.showDialog = onCancelDialog;
    },
  },
  components: {
    RegisterDialog,
  },
};
</script>
<style scoped>
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
}
@media only screen and (min-width: 426px) and (max-width: 768px) {
}

@media only screen and (min-width: 600px) {
}
@media only screen and (min-width: 376px) and (max-width: 425px) {
  .form-text-detail {
    font-size: 14px !important;
  }
}
@media only screen and (min-width: 321px) and (max-width: 375px) {
  .form-text-detail {
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 320px) {
  .form-text-detail {
    font-size: 15px !important;
  }
}
.form-text-detail {
  font-size: 20px;
  color: #fff;
}
.btn-login-text {
  font-size: 1.5em;

  letter-spacing: 0.2px;
}
.btn-line {
  background: #bb1abe;
  background-image: -webkit-linear-gradient(top, #bb1abe, #45012f);
  background-image: -moz-linear-gradient(top, #bb1abe, #45012f);
  background-image: -ms-linear-gradient(top, #bb1abe, #45012f);
  background-image: -o-linear-gradient(top, #bb1abe, #45012f);
  background-image: linear-gradient(to bottom, #bb1abe, #45012f);
  -webkit-border-radius: 5;
  -moz-border-radius: 5;
  border-radius: 5px;
  text-shadow: 0px 1px 3px #1a0216;
  -webkit-box-shadow: 0px 0px 8px #bc492c;
  -moz-box-shadow: 0px 0px 8px #bc492c;
  box-shadow: 0px 0px 8px #bc492c;

  color: #ffffff;

  padding: 4px 20px 4px 20px;
  border-top: solid #f7ecff 1px;
  border-right: solid #f9750e 1px;
  border-bottom: solid #f7ecff 1px;
  border-left: solid #f9750e 1px;
  text-decoration: none;
}

.btn-register {
  background: #f3bb2f;
  background-image: -webkit-linear-gradient(top, #f3bb2f, #ee331d);
  background-image: -moz-linear-gradient(top, #f3bb2f, #ee331d);
  background-image: -ms-linear-gradient(top, #f3bb2f, #ee331d);
  background-image: -o-linear-gradient(top, #f3bb2f, #ee331d);
  background-image: linear-gradient(to bottom, #f3bb2f, #ee331d);
  -webkit-border-radius: 5;
  -moz-border-radius: 5;
  border-radius: 5px;
  text-shadow: 0px 1px 3px #1a0216;
  -webkit-box-shadow: 0px 0px 10px #9b3bc7;
  -moz-box-shadow: 0px 0px 10px #9b3bc7;
  box-shadow: 0px 0px 10px #9b3bc7;
  color: #ffffff;

  padding: 4px 20px 4px 20px;
  border-top: solid #ce55cc 1px;
  border-right: solid #520c49 1px;
  border-bottom: solid #ce55cc 1px;
  border-left: solid #520c49 1px;
  text-decoration: none;
}
.btn-login-text {
  font-size: 1.5em;

  letter-spacing: 0.2px;
}

.shadow {
  filter: drop-shadow(10px 10px 10px #ff00ff)
    drop-shadow(-10px -10px 10px #ff00ff);
}
.invert {
  filter: invert(100%);
}
</style>