<template>
  <v-container>
    <v-footer dark class="px-0 pt-0">
      <v-card>
        <!-- <div class="slider">
          <div class="slide-track">
            <div class="slide d-flex flex-column justify-center pr-2">
              <img
                src="https://www.geministar777.com/wp-content/uploads/2020/04/Pretty-Gaming-%E0%B8%9E%E0%B8%A3%E0%B8%B4%E0%B8%95%E0%B8%95%E0%B8%B5%E0%B9%89%E0%B9%80%E0%B8%81%E0%B8%A1%E0%B8%A1%E0%B8%B4%E0%B9%88%E0%B8%87-1024x300.png"
                height="80px"
                width="100%"
                alt=""
              />
            </div>
            <div class="slide d-flex flex-column justify-center pr-2">
              <img
                src="https://pgslot356.com/wp-content/uploads/2020/05/all-1024x576-3.png"
                height="80px"
                width="100%"
                alt=""
              />
            </div>
            <div class="slide d-flex flex-column justify-center pr-2">
              <img
                src="https://www.ufa969.com/wp-content/uploads/2020/04/%E0%B8%97%E0%B8%B2%E0%B8%87%E0%B9%80%E0%B8%82%E0%B9%89%E0%B8%B2-Joker.png"
                height="80px"
                width="100%"
                alt=""
              />
            </div>
            <div class="slide d-flex flex-column justify-center pr-2">
              <img
                src="https://www.356xbet.com/wp-content/uploads/2021/03/dg-casino-logo.png"
                height="80px"
                width="100%"
                alt=""
              />
            </div>
            <div class="slide d-flex flex-column justify-center pr-2">
              <img
                src="http://infosjeunes.com/wp-content/uploads/2020/12/logo.png"
                height="80px"
                width="100%"
                alt=""
                bac
              />
            </div>
            <div class="slide d-flex flex-column justify-center pr-2">
              <img
                src="https://ambpoker.com/img/ambpoker_logo.426740ad.png"
                height="80px"
                width="100%"
                alt=""
              />
            </div>
            <div class="slide d-flex flex-column justify-center pr-2">
              <img
                src="https://www.simpleplaycasino.com/wp-content/uploads/2021/03/cropped-logo-simpleplay.png"
                height="80px"
                width="100%"
                alt=""
              />
            </div>
            <div class="slide d-flex flex-column justify-center pr-2">
              <img
                src="https://www.kaga88.com/Logo_en.png"
                height="80px"
                width="100%"
                alt=""
              />
            </div>
            <div class="slide d-flex flex-column justify-center pr-2">
              <img
                src="https://hotgraph.com/logo-v2-white.png"
                height="80px"
                width="100%"
                alt=""
              />
            </div>
          </div>
        </div> -->

        <v-card-text class="white--text pb-16 black">
          <v-row>
            <v-col class="d-flex flex-column align-start pb-1" cols="12">
              <!-- <v-img
                contain
                src="../assets/NH1168.png"
                width="80px 
                  "
              >
              </v-img> -->
            </v-col>

            <v-col class="d-flex flex-column align-start pt-0" cols="12">
              <div style="color: white">
                2021 © NH1168. All Rights Reserved. | Version: (1.0.1)
              </div></v-col
            >
            <v-col cols="12">
              <hr style="border-style: dashed; color: white" class="my-2" />
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col class="pb-0" cols="12">
                  <div style="color: black">
                    <h3 style="color: white">ช่องทางการเติมเงิน</h3>
                  </div>
                </v-col>
                <v-col cols="12" class="d-flex">
                  <div v-for="(item, index) in banks" :key="index">
                    <img
                      :src="item.src"
                      width="45px"
                      height="30px"
                      class="pr-3"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    banks: [
      { name: "กสิกร", nameId: "KBANK", src: require("../assets/kbank.png") },
      { name: "ไทยพาณิชย์", nameId: "SCB", src: require("../assets/scb.png") },
      { name: "กรุงเทพ", nameId: "BBL", src: require("../assets/bbl.png") },
      { name: "กรุงไทย", nameId: "KTB", src: require("../assets/ktb.png") },
      {
        name: "กรุงศรีอยุธยา",
        nameId: "BAY",
        src: require("../assets/bay.png"),
      },
      { name: "อิสลาม", nameId: "IBANK", src: require("../assets/ibank.png") },
      { name: "ออมสิน", nameId: "GSB", src: require("../assets/gsb.png") },
      { name: "ทหารไทย", nameId: "TTB", src: require("../assets/tmb.png") },
      { name: "ธนชาต", nameId: "TBANK", src: require("../assets/tbank.png") },
      { name: "ทิสโก้", nameId: "TISCO", src: require("../assets/tisco.png") },
      { name: "เกียรตินาคิน", nameId: "KK", src: require("../assets/kk.png") },
      {
        name: "อาคารสงเคราะห์",
        nameId: "GHB",
        src: require("../assets/ghb.jpg"),
      },
      {
        name: "แลนด์ & เฮ้าส์",
        nameId: "LHBANK",
        src: require("../assets/lh.png"),
      },
      { name: "ธกส.", nameId: "BAAC", src: require("../assets/baac.png") },
      { name: "CIMB", nameId: "CIMB", src: require("../assets/cimb.png") },
      { name: "UOB", nameId: "UOB", src: require("../assets/uob.png") },
    ],
  }),
};
</script>
<style scoped>
@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
.slider {
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.slider::before,
.slider::after {
  background: linear-gradient(
    to right,
    rgb(0, 0, 0) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  -webkit-animation: scroll 40s linear infinite;
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}
.slider .slide {
  height: 100px;
  width: 180px;
}
</style>
