<template>
  <div class="sc2">
    <v-container>
      <v-row class="justify-center">
        <v-col xl="5" lg="5" md="7" sm="8" cols="9">
          <div class="form-text-header text-center">ข้อดีที่สุดของ nh1168</div>
        </v-col>
        <v-col cols="11">
          <v-row class="justify-center">
            <v-col
              class="form-text-detail d-flex align-center py-1 px-0"
              cols="11"
              sm="9"
              lg="5"
              md="5"
            >
              <img
                class="size-bullet pr-2"
                src="../../assets/bullet.png"
                width="32px"
              />
              <div class="unfocus-text">
                <span class="focus-text"> สมัครง่าย</span> ไม่ต้องแอดไลน์
                ไม่ต้องจำยูส
              </div>
            </v-col>
            <v-col
              class="form-text-detail d-flex align-center py-1 px-0"
              cols="11"
              sm="9"
              lg="5"
              md="5"
            >
              <img
                class="size-bullet pr-2"
                src="../../assets/bullet.png"
                width="32px"
              />
              <div class="unfocus-text">
                ฝาก-ถอน <span class="focus-text">AUTO 10 วินาที</span>
              </div>
            </v-col>
            <v-col
              class="form-text-detail d-flex align-center py-1 px-0"
              cols="11"
              sm="9"
              lg="5"
              md="5"
            >
              <img
                class="size-bullet pr-2"
                src="../../assets/bullet.png"
                width="32px"
              />
              <div class="unfocus-text">
                เล่นตรงกับผู้ให้บริการ
                <span class="focus-text"> ไม่ผ่านเอเย่นต์</span>
              </div>
            </v-col>
            <v-col
              class="form-text-detail d-flex align-center py-1 px-0"
              cols="11"
              sm="9"
              lg="5"
              md="5"
            >
              <img
                class="size-bullet pr-2"
                src="../../assets/bullet.png"
                width="32px"
              />
              <div class="unfocus-text">
                ฝาก <span class="focus-text"> 1 บาท</span> ก็เดิมพันได้
              </div>
            </v-col>
            <v-col
              class="form-text-detail d-flex align-center py-1 px-0"
              cols="11"
              sm="9"
              lg="5"
              md="5"
            >
              <img
                class="size-bullet pr-2"
                src="../../assets/bullet.png"
                width="32px"
              />
              <div class="unfocus-text">
                แอดมินดูแล<span class="focus-text"> ตลอด 24 ชั่วโมง</span>
              </div>
            </v-col>
            <v-col
              class="form-text-detail d-flex align-center py-1 px-0"
              cols="11"
              sm="9"
              lg="5"
              md="5"
            >
              <img
                class="size-bullet pr-2"
                src="../../assets/bullet.png"
                width="32px"
              />
              <div class="unfocus-text">
                <span class="focus-text"> โปรโมชั่นเด็ดๆ</span> แตก
                แจกรางวัลทุกเดือน
              </div>
            </v-col>
            <v-col
              class="form-text-detail d-flex align-center py-1 px-0"
              cols="11"
              sm="9"
              lg="10"
              md="5"
            >
              <img
                class="size-bullet pr-2"
                src="../../assets/bullet.png"
                width="32px"
              />
              <div class="unfocus-text">
                ค่ายเกมชั้นนำ มีให้เลือก
                <span class="focus-text">มากกว่า 100 เกม </span> ภาพสวย เล่นง่าย
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="sc2 justify-center">
        <v-col class="pb-0" xl="5" lg="5" md="7" sm="8" cols="9">
          <div class="form-text-header text-center">เข้าเลือกคาสิโน</div>
        </v-col>
        <v-container class="pb-16 px-lx-16 px-lg-16 pt-0">
          <SectionGamePlay />
        </v-container>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import SectionGamePlay from "../../components/s_content_game.vue";
export default {
  components: {
    SectionGamePlay,
  },
  data: () => ({
    data_advantages: [
      {
        text: "ฝาก ถอน อัตโนมัติ 10 วินาที",
      },
      {
        text: "เล่นตรงกับผู้ให้บริการทั้งหมด ไม่ผ่านเอเย่นต์",
      },
      {
        text: "ฝาก ถอน ไม่จำกัดจำนวนครั้ง และจำนวนเงิน",
      },
      {
        text: "  แทงเดิมพันสูงสุด 500,000 บาท / ไม้",
      },
      {
        text: "  สมัครง่าย ไม่ต้องแอดไลน์ ไม่ต้องจำยูส",
      },
      {
        text: "แอดมินดูแลอย่างมืออาชีพ ตลอด 24 ชั่วโมง",
      },
      {
        text: "  โปรโมชั่นรายวัน โปรโมชั่นรายเดือน ดีสุดใน 3 โลก",
      },
    ],
  }),
};
</script>
<style scoped>
@media only screen and (max-width: 1440px) {
  .size-bullet {
    width: 40px !important;
  }
  .form-text-header {
    font-size: 30px !important;
  }
  .form-text-detail {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 1536px) {
  .size-bullet {
    width: 40px !important;
  }
  .form-text-header {
    font-size: 30px !important;
  }
  .form-text-detail {
    font-size: 18px !important;
  }
}
@media only screen and (min-width: 1536px) and (max-width: 1736px) {
  .form-text-header {
    font-size: 30px !important;
  }
  .form-text-detail {
    font-size: 18px !important;
  }
  .size-bullet {
    width: 40px !important;
  }
}
@media only screen and (max-width: 1160px) {
}
@media only screen and (max-width: 1024px) {
}
@media only screen and (max-width: 961px) {
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .form-text-header {

  padding: 20px !important;
}
}
@media only screen and (min-width: 769px) and (max-width: 960px) {
  .text-slot9th {
    margin-top: -180px;
  }

  .form-text-header {
    font-size: 30px !important;
  }
  .form-text-detail {
    font-size: 15px !important;
  }
  .size-bullet {
    width: 35px !important;
  }
}
@media only screen and (max-width: 600px) {
  .login-icon-size {
    font-size: 20px !important;
  }
  .btn-login-text {
    font-size: 0.9em !important;
    font-weight: 400 !important;
  }
  .login-box {
    padding: 0px 0px 0px 0px !important;
    /* margin-top: -385px !important; */
    width: 100% !important;
  }
  .resize-logo-section {
    /* margin-top: -70px !important; */
    padding-top: 0px !important;
  }
  .resize-logo-img {
    padding-top: 60px !important;
    width: 100% !important;
  }
  .text-slot9th {
  }

  .form-text-header {
    font-size: 20px !important;
  }
  .form-text-detail {
    font-size: 15px !important;
  }
  .size-bullet {
    width: 35px !important;
  }
  .btn-line-regis {
    width: 120px !important;
  }
}
@media only screen and (max-width: 375px) {
  .header {
    height: 635px !important;
    background-position-y: 255px !important;
  }
  .form-text-header {
    padding: 20px !important;
  }
}

.form-text-header {
  text-shadow: 2px 2px #000000;
  font-size: 30px;
  color: #fff;
  font-weight: bold;
  background: url("../../assets/Box-Topic.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
 
}
.form-text-detail {
  font-size: 28px;
  color: #fff;
}
.unfocus-text {
  font-size: 16px;
  text-shadow: 2px 2px #000000;
}
.focus-text {
  font-size: 23px;
  text-shadow: 2px 2px #000000;
}
</style>