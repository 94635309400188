var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"padding-content-custom"},[_c('v-container',{staticClass:"pb-16"},[_c('v-row',{staticClass:"pb-16"},[_c('v-col',{class:_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
            ? 'section-content-custom-s4-mb'
            : 'parents',attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{class:_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
                ? 'form-text-header-mb '
                : 'form-text-header-desktop',attrs:{"xl":"5","lg":"5","md":"7","sm":"7","cols":"9"}},[_c('div',{staticClass:"text-center"},[_vm._v("เกมสล็อต ยอดฮิต")])]),_c('v-col',[_c('v-container',[_c('div',[_c('v-row',{class:_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
                      ? 'padding-game-mb'
                      : 'padding-game-desktop'},_vm._l((_vm.data_game),function(item,index){return _c('v-col',{key:index,class:_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
                        ? 'custom3cols'
                        : 'custom5cols',attrs:{"xs":"3","sm":"3","md":"3","lg":"2","cols":"4"}},[_c('div',{staticClass:"container-custom",on:{"click":function($event){return _vm.$router.push({ path: '/game' })}}},[_c('div',[_c('v-img',{staticClass:"image",attrs:{"src":item.img},on:{"mouseover":function($event){return _vm.playSound()}}})],1),_c('div',{staticClass:"text-center subgame"},[_vm._v(" "+_vm._s(item.title)+" ")])])])}),1)],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }