<template>
  <v-row justify="center">
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xs ? true : false"
      max-width="600"
      @click:outside="onCancelDialog()"
      v-model="dialog"
    >
      <v-card class="border-card">
        <v-card-title class="headline justify-center">
          <div style="position: absolute; right: 3px; top: 3px; z-index: 5">
            <v-btn
              @click="onCancelDialog()"
              class="ma-2"
              outlined
              x-small
              fab
              color="red"
            >
              <v-icon>mdi-close-thick</v-icon>
            </v-btn>
          </div>
          <v-row class="justify-center">
            <v-col
              cols="12"
              class="pb-0 d-flex flex-column justify-center text-center"
              ><h3 class="text-title">สมัครสมาชิก</h3></v-col
            >
          </v-row></v-card-title
        >

        <v-card-text>
          <v-container>
            <v-form ref="formRegister" v-model="validate" lazy-validation>
              <v-row>
                <v-col cols="12" align="start">
                  <v-text-field
                    v-model="formRegister.phone"
                    dark
                    color="white"
                    label="เบอร์โทรศัพท์"
                    :rules="[rules.required, rules.tel]"
                    prepend-inner-icon="mdi-phone"
                  ></v-text-field>
                  <v-text-field
                    @click:append="passwordHide = !passwordHide"
                    :append-icon="
                      passwordHide ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
                    "
                    :type="passwordHide ? 'password' : 'text'"
                    v-model="formRegister.password"
                    dark
                    color="white"
                    label="รหัสผ่าน"
                    :rules="[rules.required]"
                    prepend-inner-icon="mdi-lock"
                    hint="รหัสผ่านต้องมีความยาว 6-20 ตัวอักษร"
                  ></v-text-field>
                  <v-text-field
                    @click:append="repeatepasswordHide = !repeatepasswordHide"
                    :append-icon="
                      repeatepasswordHide
                        ? 'mdi-eye-off-outline'
                        : 'mdi-eye-outline'
                    "
                    v-model="formRegister.repeatPassword"
                    :type="repeatepasswordHide ? 'password' : 'text'"
                    dark
                    color="white"
                    label="ยืนยันรหัสผ่าน"
                    :rules="[rules.required, passwordConfirmationRule]"
                    prepend-inner-icon="mdi-lock"
                    hint="รหัสผ่านต้องมีความยาว 6-20 ตัวอักษร"
                  ></v-text-field>
                  <v-select
                    dark
                    color="white"
                    class="pb-5"
                    v-model="formRegister.bankType"
                    :items="banks"
                    :item-text="'name'"
                    :item-value="'nameId'"
                    name="bankType"
                    menu-props="auto"
                    label="กรุณาเลือกธนาคาร"
                    hide-details
                    :rules="[rules.required]"
                    prepend-icon="mdi-bank"
                    single-line
                  >
                    <template v-slot:item="{ item }">
                      <img
                        :src="item.src"
                        width="45px"
                        height="30px"
                        class="pr-3"
                      />
                      {{ item.name }}
                    </template>
                  </v-select>

                  <v-text-field
                    v-model="formRegister.bankAcc"
                    :rules="[rules.required, rules.accBank]"
                    dark
                    color="white"
                    label="เลขบัญชี"
                  ></v-text-field>
                  <div class="text-warning text-start">
                    * เลขบัญชีต้องตรงกับบัญชีที่ใช้โอนเงิน
                  </div>

                  <v-row class="pa-3">
                    <v-text-field
                      v-model="formRegister.firstname"
                      :rules="[rules.required]"
                      dark
                      color="white"
                      class="pr-3"
                      label="ชื่อ"
                    ></v-text-field>
                    <v-text-field
                      v-model="formRegister.lastname"
                      :rules="[rules.required]"
                      dark
                      color="white"
                      label="นามสกุล"
                    ></v-text-field>
                  </v-row>
                  <div class="text-warning text-start">
                    * ชื่อ-สกุลต้องตรงกับบัญชีธนาคาร
                  </div>

                  <v-text-field
                    v-model="formRegister.lineId"
                    dark
                    color="white"
                    label="ไอดีไลน์"
                  ></v-text-field>
                  <v-select
                    v-if="sourceEnable"
                    dark
                    color="white"
                    class="pb-1"
                    v-model="formRegister.source"
                    :items="sources"
                    :item-text="'sourceName'"
                    :item-value="'sourceId'"
                    name="source"
                    menu-props="auto"
                    label="ท่านรู้จักเราได้อย่างไร"
                    hide-details
                    prepend-icon="mdi-account-box"
                    single-line
                  ></v-select>
                  <!-- <div
                  v-if="referer"
                  class="pt-3 text-center"
                  style="color: white; font-size: 18px"
                >
                  รหัสผู้แนะนำ : {{ referer }}
                </div> -->
                  <v-btn
                    :height="$vuetify.breakpoint.xl ? '50px' : '48px'"
                    class="btn-orange mt-4"
                    block
                    depressed
                    @click="signUp()"
                  >
                    <img
                      class="invert"
                      img
                      src="../assets/add-user.png"
                      :width="$vuetify.breakpoint.xl ? '25px' : '20px'"
                    />
                    <div style="color: white" class="pl-2">
                      <h3>สมัครสมาชิก</h3>
                    </div>
                  </v-btn>
                  <div class="mt-2 text-center" style="color: red">
                    <v-container>
                      หากพบว่ามีการสมัครหลายบัญชีเพื่อปั๊มเทิร์นหรือโปรโมชั่น
                      หรือชื่อสกุลไม่ตรงกับบัญชีธนาคาร
                      ทีมงานขอสงวนสิทธิ์ในการระงับบัญชีทันที
                    </v-container>
                    <ErrorDialog
                      :dataError="dataError"
                      :onShowErrorDialog="showDialog"
                      @onCancelDialog="onCancelErrorDialog($event)"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import ErrorDialog from "./ErrorDialog";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      sourceEnable: (state) => state.user.sourceEnable,
    }),
    passwordConfirmationRule() {
      return () =>
        this.formRegister.password === this.formRegister.repeatPassword ||
        "รหัสผ่านไม่ตรงกัน";
    },
  },
  data: () => ({
    rules: {
      required: (v) => !!v || "กรุณากรอกข้อมูล",
      passZero: (v) => v >= 0 || "กรุณากรอกข้อมูลให่ถูกต้อง",
      tel: (v) => v.length <= 10 || "เบอร์โทรศัพท์ไม่เกิน 10 หลัก",
      accBank: (v) => v.length <= 12 || "เลขบัญชีไม่เกิน 12 หลัก",
    },
    e1: "เลือกธนาคาร",
    e2: "ท่านรู้จักเราได้อย่างไร",
    banks: [
      // {
      //   name: "True Wallet",
      //   nameId: "WALLET",
      //   src: require("../assets/truemoneywallet.webp"),
      // },
      { name: "กสิกร", nameId: "KBANK", src: require("../assets/kbank.png") },
      { name: "ไทยพาณิชย์", nameId: "SCB", src: require("../assets/scb.png") },
      { name: "กรุงเทพ", nameId: "BBL", src: require("../assets/bbl.png") },
      { name: "กรุงไทย", nameId: "KTB", src: require("../assets/ktb.png") },
      {
        name: "กรุงศรีอยุธยา",
        nameId: "BAY",
        src: require("../assets/bay.png"),
      },
      { name: "อิสลาม", nameId: "IBANK", src: require("../assets/ibank.png") },
      { name: "ออมสิน", nameId: "GSB", src: require("../assets/gsb.png") },
      {
        name: "ทหารไทยธนชาต",
        nameId: "TTB",
        src: require("../assets/tmb.png"),
      },

      { name: "ทิสโก้", nameId: "TISCO", src: require("../assets/tisco.png") },
      {
        name: "อาคารสงเคราะห์",
        nameId: "GHB",
        src: require("../assets/ghb.jpg"),
      },
      {
        name: "แลนด์ & เฮ้าส์",
        nameId: "LHBANK",
        src: require("../assets/lh.png"),
      },
      // { name: "ธกส.", nameId: "BAAC", src: require("../assets/baac.png") },
      { name: "CIMB", nameId: "CIMB", src: require("../assets/cimb.png") },
      { name: "UOB", nameId: "UOB", src: require("../assets/uob.png") },
    ],
    sources: [{ sourceId: 0, sourceName: "ไม่ระบุ" }],
    loading: false,
    formRegister: {
      phone: "",
      password: "",
      repeatPassword: "",
      firstname: "",
      lastname: "",
      bankType: "",
      bankAcc: "",
      lineId: "",
      source: "",
      referer: "",
    },
    validate: true,
    showDialog: false,
    dataError: {},
    passwordHide: true,
    repeatepasswordHide: true,
  }),
  props: ["dialog"],
  methods: {
    opentoast(massage) {
      this.$toast.error(massage, {
        position: "top-right",
      });
    },
    onCancelErrorDialog(onCancelErrorDialog) {
      // this.showDialog =[{onShowDialog:false},];
      this.showDialog = onCancelErrorDialog;
    },
    onCancelDialog() {
      this.$emit("onCancelDialog", false);
    },
    async getSources() {
      let res = await this.$store.dispatch("user/userSources");
      if (res != null) {
        this.sources.push.apply(this.sources, res.sources);
      }
    },
    async signUp() {
      if (this.$refs.formRegister.validate()) {
        var min = 30;
        var referer = localStorage.getItem("referer");
        var source = localStorage.getItem("source");
        if (referer) {
          let data = JSON.parse(referer);

          if (new Date().getTime() - data.expiredAt > min * 60 * 1000) {
            localStorage.removeItem("referer");
            this.formRegister.referer = null;
          } else {
            this.formRegister.referer = data.referer;
          }
        } else {
          this.formRegister.referer = null;
        }
        if (source) {
          let data = JSON.parse(source);

          if (new Date().getTime() - data.expiredAt > min * 60 * 1000) {
            localStorage.removeItem("source");
            this.formRegister.source = null;
          } else {
            this.formRegister.source = data.source;
          }
        } else {
          this.formRegister.source = null;
        }
        this.loading = true;

        let res = await this.$store.dispatch(
          "user/userSignUp",
          this.formRegister
        );
        // if (res != null) {
        //   this.showDialog = true;
        //   this.dataError = res;
        // } else {
        // }
        if (res != null && res.message == "สมัครสมาชิกเรียบร้อย!") {
          this.onCancelDialog();
          // console.log("dddddddd");
          this.dataError = res;
          this.showDialog = true;
          let body = {
            username: this.formRegister.phone,
            password: this.formRegister.password,
          };
          let res = await this.$store.dispatch("user/userAuthen", body);
          if (res === true) {
            this.$router.push("/").catch(() => {});
            this.getBalance();
          } else {
            this.opentoast(res);
          }
        } else {
          this.dataError = res;
          this.showDialog = true;
        }
      }
    },
  },
  components: {
    ErrorDialog,
  },
  created() {
    this.getSources();
  },
  mounted() {
    this.$store.commit("user/setSource");
  },
};
</script>

<style scoped>
.border-card {
  opacity: 0.98;
  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(150, 16, 151, 0.8);
  background: linear-gradient(180deg, #d800da, #1c0a24);
  box-shadow: inset 0 0 10px #ee331d !important ;
}

.text-warning {
  color: rgba(246, 197, 45, 1);
}
.hr-style {
  height: 1px;
  background: linear-gradient(
    0deg,
    rgba(128, 94, 23, 1) 0%,
    rgba(246, 197, 45, 1) 30%,
    rgba(246, 197, 45, 1) 70%,
    rgba(128, 94, 23, 1) 100%
  );
}
</style>
