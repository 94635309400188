<template>
  <div class="padding-content-custom">
    <v-container class="pb-16">
      <v-row class="pb-16">
        <v-col
          cols="12"
          :class="
            $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
              ? 'section-content-custom-s4-mb'
              : 'parents'
          "
        >
          <v-row>
            <v-col
              :class="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                  ? 'form-text-header-mb '
                  : 'form-text-header-desktop'
              "
              xl="5"
              lg="5"
              md="7"
              sm="7"
              cols="9"
            >
              <div class="text-center">เกมสล็อต ยอดฮิต</div>
            </v-col>

            <v-col>
              <v-container>
                <div>
                  <v-row
                    :class="
                      $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                        ? 'padding-game-mb'
                        : 'padding-game-desktop'
                    "
                  >
                    <v-col
                      v-for="(item, index) in data_game"
                      :key="index"
                      xs="3"
                      sm="3"
                      md="3"
                      lg="2"
                      cols="4"
                      :class="
                        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                          ? 'custom3cols'
                          : 'custom5cols'
                      "
                    >
                      <div
                        @click="$router.push({ path: '/game' })"
                        class="container-custom"
                      >
                        <div>
                          <v-img
                            @mouseover="playSound()"
                            class="image"
                            :src="item.img"
                          ></v-img>
                        </div>
                        <div class="text-center subgame">
                          {{ item.title }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  methods: {
    playSound() {
      var audio = new Audio(
        require("../../assets/mixkit-soap-bubble-sound-2925.wav")
      );
      audio.play();
    },
  },
  data: () => ({
    data_game: [
      {
        title: "Ancient Artifacts",

        img: require("../../assets/ancientartifactgw.bb0a0431.png"),
      },
      {
        title: "Burning Pearl",

        img: require("../../assets/burningpearlgw.e8c4e5a3.png"),
      },
      {
        title: "China",

        img: require("../../assets/chinagw.f772fd71.png"),
      },
      {
        title: "Fish World",

        img: require("../../assets/fishworld.f5425ad2.png"),
      },
      {
        title: "Four Dragons",

        img: require("../../assets/fourdragonsgw.a537eb42.png"),
      },
      {
        title: "Horus Eye",

        img: require("../../assets/horuseyegw.a28ab237.png"),
      },
      {
        title: "Lightning God",

        img: require("../../assets/lightninggodgw.926c1df7.png"),
      },
      {
        title: "Lucky Rooster",

        img: require("../../assets/luckyrooster.97c47c0e.png"),
      },
      {
        title: "Money Vault",

        img: require("../../assets/moneyvaultgw.502f09a4.png"),
      },
      {
        title: "Neptune Treasure",

        img: require("../../assets/neptunetreasuregw.06643360.png"),
      },
      {
        title: "Nezha",

        img: require("../../assets/nezhagw.95a971c7.png"),
      },
      {
        title: "peach banquet",

        img: require("../../assets/peachbanquetgw.be2d534f.png"),
      },
      {
        title: "Taishang Laojun",

        img: require("../../assets/taishanglaojungw.a668a85d.png"),
      },
      {
        title: "Wild Giant Panda",

        img: require("../../assets/wildgiantpandagw.52d87b72.png"),
      },
      {
        title: "Witch's Brew",

        img: require("../../assets/witchsbrewgw.976d03e6.png"),
      },
    ],
  }),
};
</script>
<style scoped>
@media only screen and (max-width: 1440px) {
  
}

@media only screen and (max-width: 2560px) {
  .form-text-header-desktop {
    top: -5% !important;
  }
}
@media only screen and (min-width: 1536px) and (max-width: 1736px) {
    .form-text-header-desktop {
    top: -7% !important;
  }
}
@media only screen and (max-width: 1160px) {
}
@media only screen and (max-width: 1024px) {
}
@media only screen and (max-width: 961px) {
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .form-text-header-mb {
    top: -3% !important;
  }
  .subgame {
    font-size: 18px !important;
  }
}
@media only screen and (min-width: 769px) and (max-width: 960px) {
}
@media only screen and (max-width: 600px) {
}
@media only screen and (max-width: 375px) {
}
.custom5cols {
  width: 20%;
  max-width: 20%;
  flex-basis: 20%;
  font-size: 17px;
}
.custom3cols {
  width: 33.33%;
  max-width: 33.33%;
  flex-basis: 33.33%;
  font-size: 10px;
  padding: 15px 5px 5px 5px;
}
.sc3 {
  background: url("../../assets/BG-SC3-2.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.box {
  background-color: #f3ff47;
  padding: 30px;
  font-family: courier;
  text-align: center;
  font-size: 2rem;
  position: relative;
  top: -60px;
}

.parents {
  background-image: url("../../assets/ta-grang.png");
  background-repeat: repeat;
  position: relative;
  border: 10px solid #ffffff;
  border-radius: 20px 20px 20px 20px;
  box-shadow: inset 1px -1px 10px 10px #fa55fa, 1px -1px 10px 10px #fa55fa;
}

.form-text-header-desktop {
  font-size: 30px;
  color: #fff;
  font-weight: bold;
  background: url("../../assets/Box-Topic.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 25px;
  padding-left: 40px;
  padding-right: 40px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -7%;
}

.form-text-header-mb {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  background: url("../../assets/Box-Topic.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -4.9%;
}

.subgame {
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 233, 47, 1) 0%,
    rgba(242, 68, 57, 1) 100%
  );
}

.container-custom:hover .image {
  opacity: 1;
  transform: scale(1.1);
}

.container-custom:hover .middle {
  opacity: 1;
}
.container-custom {
  position: relative;
  width: 100%;
}
.image {
  cursor: pointer;
  opacity: 0.9;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;

  text-align: center;
}
.section-content-custom-s4-mb {
  border: 5px double #ffffff;
  border-radius: 20px 20px 20px 20px;
  box-shadow: inset 1px -1px 10px 6px #d7400d, 1px -1px 10px 2px #d7400d;
  background: rgba(57, 7, 56, 0.6);
  position: relative;
}

.padding-game-desktop {
  padding: 30px 30px 30px 30px;
}
.padding-game-mb {
  padding: 0;
}
.padding-content-custom {
  padding-bottom: 80px;
  padding-left: 20px;
  padding-right: 20px;
}
</style>