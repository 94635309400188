<template>
  <div>
    <v-bottom-navigation
      :height="$vuetify.breakpoint.xs ? '70' : '100'"
      :value="value"
      grow
      class="d-flex appbar"
      fixed
    >
      <v-btn
        class="border-left-custom border-right-custom"
        link
        @click="$router.push({ path: '/' }).catch(() => {})"
      >
        <span class="text-size" style="color: white">หน้าแรก</span>

        <v-icon :size="$vuetify.breakpoint.xs ? '25px' : '40px'" color="white"
          >mdi-home-circle</v-icon
        >
      </v-btn>
      <v-btn
        class="border-right-custom"
        link
        @click="$router.push({ path: '/game' }).catch(() => {})"
      >
        <span class="text-size" style="color: white">เล่นเกม</span>
        <v-icon :size="$vuetify.breakpoint.xs ? '25px' : '40px'" color="white"
          >mdi-play-circle</v-icon
        >
      </v-btn>
      <v-btn
        class="border-right-custom"
        v-if="userProfile.length != 0"
        @click="$router.push({ path: '/transaction' }).catch(() => {})"
      >
        <span class="text-size" style="color: white">ฝาก - ถอน</span>
        <v-icon :size="$vuetify.breakpoint.xs ? '25px' : '40px'" color="white"
          >mdi-cash-multiple</v-icon
        >
      </v-btn>

      <v-btn
        target="_blank"
        :href="settingCheck"
        v-if="userProfile.length == 0"
        class="border-right-custom"
        link
      >
        <span class="text-size" style="color: white">ติดต่อเรา</span>

        <v-icon :size="$vuetify.breakpoint.xs ? '25px' : '40px'" color="white"
          >mdi-email</v-icon
        >
      </v-btn>

      <v-btn
        v-if="userProfile.length != 0"
        class="border-right-custom"
        link
        @click.stop="showDialogConfirm = true"
      >
        <span class="text-size" style="color: white">ออกจากระบบ</span>

        <v-icon :size="$vuetify.breakpoint.xs ? '25px' : '40px'" color="white"
          >mdi-logout-variant</v-icon
        >
      </v-btn>
    </v-bottom-navigation>
    <ConfirmDialog
      :dataConfirmDialog="dataConfirmDialog"
      :onShowDialog="showDialogConfirm"
      @onCancelConfirmDialog="onCancelConfirmDialog($event)"
    />
    <TransactionDialog
      :dialog="showDialog"
      @onCancelDialog="onCancelDialog($event)"
    />
  </div>
</template>

<script>
import ConfirmDialog from "./ConfirmDialog";
import { mapState } from "vuex";
import TransactionDialog from "./TransactionDialog";
export default {
  computed: {
    settingCheck() {
      if (this.LineSetting) {
        let obj = this.LineSetting.find((val) => val.setting === "LINE");
        return obj ? obj.value : "";
      }
    },
    ...mapState({
      LineSetting: (state) => state.setting.LineSetting,
      userProfile: (state) => state.user.userProfile,
    }),
  },
  data: () => ({
    dataConfirmDialog: { title: "ต้องการออกจากระบบใช่หรือไม่?" },
    value: 1,
    showDialog: false,
    showDialogConfirm: false,
  }),
  components: { TransactionDialog, ConfirmDialog },
  methods: {
    onCancelConfirmDialog(onCancelConfirmDialog) {
      this.showDialogConfirm = onCancelConfirmDialog.isOpen;
      if (onCancelConfirmDialog.onAccept == true) {
        this.onLogOut();
      }
    },
    onLogOut() {
      localStorage.removeItem("toKen");
      this.$store.commit("user/clearUserDataWhenLogout");
      this.$router.push("/").catch(() => {});
    },

    onCancelDialog(onCancelDialog) {
      this.showDialog = onCancelDialog;
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 450px) {
  .text-size {
    font-size: 13px !important;
  }
}
.appbar {
  background: linear-gradient(
    180deg,
    rgba(101, 36, 128, 1) 0%,
    rgba(28, 10, 36, 1) 100%
  );
}
.button-viztype {
  border: none;
  height: 75px;
  font-size: 1.3em;
  border-left-style: solid;
  border-left-width: 7px;
}

.button-distribution {
  border-left-color: #8d34a2;
}

.mb-custom {
  font-size: 25px !important;
}
.desktop-custom {
  font-size: 40px;
  color: white;
}
.border-left-custom {
  border-left-color: coral !important;
  border-left: 2px solid;
  border-image: linear-gradient(0deg, rgb(92, 33, 117), rgb(92, 33, 117) 50%) 1;
}
.border-right-custom {
  border-right-color: coral !important;
  border-right: 2px solid;
  border-image: linear-gradient(0deg, rgb(92, 33, 117) 0%, rgb(92, 33, 117) 50%)
    1;
}
.test {
  margin-top: -100px;
}
.text-size {
  font-size: 18px;
}
</style>
