import { globalAxios } from "../../../axios-config";
import config from "../../config/config";
const state = () => ({
  LineSetting: null,
});

const mutations = {
  setDataSettingLine(state, payload) {
    state.LineSetting = payload;
  },
};

const actions = {
  async getSetting({ commit }) {
    try {
      let response = await globalAxios({
        method: "get",
        url: `/${config.api.setting}`,
      });
    
      if (response != null) {
        commit("setDataSettingLine", response.data.datas);
      }
    
      return true;
    } catch (error) {
    
      return error.response.data.errors;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
