<template>
  <div>
    <v-row justify="center">
      <v-dialog
        scrollable
        :max-width="$vuetify.breakpoint.name == 'xs' ? '100%' : '500px'"
        @click:outside="onCancelNewsDialog()"
        v-model="onShowNewsDialog"
      >
        <v-card class="border-card">
          <v-row class="justify-center">
            <v-col class="d-flex justify-center" cols="3">
              <!-- <v-img
                class="main-logo"
                contain
                src="../assets/NH1168.png"
                width="50% 
                  "
              >
              </v-img
            > -->
            </v-col>
          </v-row>
          <div style="position: absolute; right: 3px; top: 3px; z-index: 5">
            <v-btn
              @click="onCancelNewsDialog()"
              class="ma-2"
              outlined
              x-small
              fab
              color="red"
            >
              <v-icon>mdi-close-thick</v-icon>
            </v-btn>
          </div>
          <v-card-title class="headline justify-center">
            <v-row class="justify-center">
              <v-col
                style="color: gold"
                cols="12"
                class="pb-0 d-flex justify-center"
              >
                <div class="text-title">ประกาศ !!</div>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text class="pa-0 pt-4">
            <v-container>
              <v-row>
                <v-card-text
                  style="height: 300px; border-radius: 5px; color: white"
                  class="text-center mt-2"
                >
                  <div class="mt-2">
                    <b> {{ dataNews.title }}</b>
                  </div>
                  <div
                    class="horizontal"
                    style="background: grey; opacity: 0.2"
                  ></div>
                  <div v-html="dataNews.content" class="mt-1"></div>
                </v-card-text>
                <v-col class="d-flex justify-center align-center">
                  <v-checkbox
                    v-model="isShow"
                    dense
                    hide-details
                    dark
                    color="white"
                    label="ไม่แสดงหน้านี้อีก"
                  ></v-checkbox
                ></v-col>
                <v-col cols="12">
                  <v-btn
                    @click="onCancelNewsDialog()"
                    width="100%"
                    class="btn-orange"
                    depressed
                  >
                    <div class="btn-login-text pl-3" style="color: white">
                      <v-icon small class="pr-1">mdi-check-all</v-icon>
                      ตกลง
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
export default {
  data: () => ({
    isShow: false,
  }),
  props: ["onShowNewsDialog", "dataNews"],
  methods: {
    onCancelNewsDialog() {
      let cloneItem = this.dataNews;
      if (this.isShow == true) {
        this.$emit("onCancelNewsDialog", cloneItem);
        this.isShow = false;
      } else {
        this.$emit("onCancelNewsDialog");
      }
    },
  },
};
</script>
<style scoped>
.divider-custom {
  background: linear-gradient(
    90deg,
    rgba(255, 235, 0, 1) 0%,
    rgba(121, 9, 9, 1) 50%,
    rgba(255, 235, 0, 1) 100%
  );
}
.text-title {
  font-size: 25px;
  color: white;
  padding: 0px 20px 0px 20px;
  background-repeat: repeat;
  position: relative;
  border: 4px solid #ffffff;
  border-radius: 20px 20px 20px 20px;
  box-shadow: inset 1px -1px 10px 10px #fa55fa, 1px -1px 10px 10px #fa55fa;
}
.border-card {
  border-radius: 8px !important;
  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(150, 16, 151, 0.8);
  background: url("../assets/BG-SC1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 10px #ee331d !important ;
}
</style>
