<template>
  <div>
    <v-dialog
      max-width="600"
      v-model="dialog"
      @click:outside="closeAlertDialog()"
    >
      <div class="card-alert-screen">
        <v-row no-gutters>
          <v-col cols="12" style="text-align: center" class="pb-3">
            <v-icon style="font-size: 3em; color: white"
              >mdi-alert-circle</v-icon
            >
            <div>
              <h3 style="color: #FFD700">
                {{ this.txtMessage }}
              </h3>
            </div>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn class="btn_block" @click="closeAlertDialog()">ตกลง</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["dialog", "txtMessage"],
  methods: {
    closeAlertDialog() {
      this.$emit("closeAlertDialog");
    },
  },
};
</script>

<style scoped>
.btn_block {
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, #eb77cf 0.8%, #8271f2);
  color: rgb(255, 255, 255) !important;
  width: 150px;
}
.card-alert-screen {
  border-radius: 8px !important;
  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(150, 16, 151, 0.8);
  background: url("../../assets/BG-SC1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 10px #ee331d !important ;
}
</style>