<template>
  <div>
    <v-dialog
      max-width="600"
      v-model="dialog"
      @click:outside="closeAlertDialog()"
    >
      <div class="card-alert-screen">
        <v-row no-gutters>
          <v-col cols="12" style="text-align: center">
            <v-icon style="font-size: 3em">mdi-help-circle</v-icon>
            <h3 style="color: white">
              {{ this.txtMessage }}
            </h3>
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-column justify-center align-center py-2"
          >
            <v-img width="15%" class="box" :src="confirmImg"></v-img>
          </v-col>
          <v-col class="text-center pb-5" cols="12"
            >{{ this.nameBet }} - {{ this.amount }} บาท
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn
              class="btn_block mr-1"
              @click="closeAlertDialog(true)"
              :loading="isLoading"
              width="120px"
              >ยืนยัน</v-btn
            >
            <v-btn
              width="120px"
              class="btn_block ml-1"
              @click="closeAlertDialog(false)"
              >ยกเลิก</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: [
    "dialog",
    "txtMessage",
    "isLoading",
    "confirmImg",
    "amount",
    "nameBet",
  ],
  methods: {
    closeAlertDialog(isConfirm) {
      this.$emit("closeAlertDialog", isConfirm);
    },
  },
};
</script>

<style scoped>
.btn_block {
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, #eb77cf 0.8%, #8271f2);
  color: rgb(255, 255, 255) !important;
  width: 150px;
}
.card-alert-screen {
  background: rgb(0, 14, 43, 1);
  box-shadow: inset 0 0 15px #77a5eb !important;
  border-radius: 10px !important;
  border: 4px solid rgba(155, 225, 255, 0.96) !important;
  padding: 10px;
}
.box {
  animation-name: rotate;
  animation-duration: 0.7s;
  animation-iteration-count: 5;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(45deg);
  }
}
</style>